import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { standardizeDateHandling } from '../../utils/dateUtils';

const BrewForm = ({ brewState, setBrewState, selectedVessel, vessels, beerBrands, handleNewBatchSubmit, handleCancel }) => {
    const selectedVesselData = vessels.find(vessel => vessel.fermentationvesselid === selectedVessel) || {};

    const defaultCrashDateDistance = 12; // Default days from brew to crash BREW TO CRASH DAYS 
    const defaultDryHopDateDistance = 5; // Default days from brew to dry hop BREW TO DRY HOP DAYS 
    const defaultEstimatedPackagingDateDistance = 4; // Default days from crash to packaging CRASH TO PACKAGE DAYS 

    const calculateDates = (brewDate) => {
        const selectedBrand = beerBrands.find(brand => brand.beerbrandid === parseInt(brewState.beerbrandid, 10)) || {};

        if (!brewDate) return {};

        const crashDateDistance = selectedBrand.crashdatetime || defaultCrashDateDistance;
        const dryHopDateDistance = selectedBrand.dryhopdatetime || defaultDryHopDateDistance;
        const estimatedPackagingDateDistance = selectedBrand.estimatedpackagingdatetime || defaultEstimatedPackagingDateDistance;

        const dryHopDate = dryHopDateDistance ? moment(brewDate).add(dryHopDateDistance, 'days') : null;
        const crashDate = moment(brewDate).add(crashDateDistance, 'days');
        const estimatedPackagingDate = moment(crashDate).add(estimatedPackagingDateDistance, 'days');

        return {
            brewdate: standardizeDateHandling.toStorageFormat(brewDate),
            dryhopdate: dryHopDate ? dryHopDate.toDate() : null,
            crashdate: crashDate.toDate(),
            estimatedpackagingdate: estimatedPackagingDate.toDate(),
        };
    };

    useEffect(() => {
        if (brewState.brewdate) {
            const dates = calculateDates(standardizeDateHandling.fromStorageFormat(brewState.brewdate));
            setBrewState(prevState => ({ ...prevState, ...dates }));
        }
    }, [brewState.brewdate, setBrewState]);

    const handleBrewDateChange = (date) => {
        // Use the new function for initial date selection
        const adjustedDate = standardizeDateHandling.handleInitialDateSelection(date);
        const standardizedDate = standardizeDateHandling.toStorageFormat(adjustedDate);
        const dates = calculateDates(standardizedDate);
        setBrewState(prevState => ({ ...prevState, ...dates }));
    };

    const handleDateChange = (date, field) => {
        setBrewState(prevState => ({ ...prevState, [field]: date }));
    };

    // Function to clear the dry hop date if the user decides not to include it
    const clearDryHopDate = () => {
        setBrewState(prevState => ({ ...prevState, dryhopdate: null }));
    };

    return (
        <div>
            <h3>Create New Batch in FV{selectedVessel}</h3>
            <p>Capacity: {selectedVesselData.capacity}</p>
            <form>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label>Brand:</label>
                            </td>
                            <td>
                                <select
                                    value={brewState.beerbrandid || ''}
                                    onChange={(e) => setBrewState({ ...brewState, beerbrandid: e.target.value })}
                                >
                                    <option value="">Select a Brand</option>
                                    {beerBrands.map((brand) => (
                                        <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                            {brand.brandname}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Brew Date:</label>
                            </td>
                            <td>
                                <DatePicker
                                    selected={brewState.brewdate ? standardizeDateHandling.fromStorageFormat(brewState.brewdate) : null}
                                    onChange={handleBrewDateChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Dry Hop Date:</label>
                            </td>
                            <td>
                                <DatePicker
                                    selected={brewState.dryhopdate ? new Date(brewState.dryhopdate) : null}
                                    onChange={(date) => handleDateChange(date, 'dryhopdate')}
                                />
                                <button type="button" onClick={clearDryHopDate}>
                                    x
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Crash Date:</label>
                            </td>
                            <td>
                                <DatePicker
                                    selected={brewState.crashdate ? new Date(brewState.crashdate) : null}
                                    onChange={(date) => handleDateChange(date, 'crashdate')}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Packaging Date:</label>
                            </td>
                            <td>
                                <DatePicker
                                    selected={brewState.estimatedpackagingdate ? new Date(brewState.estimatedpackagingdate) : null}
                                    onChange={(date) => handleDateChange(date, 'estimatedpackagingdate')}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Signoff:</label>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={brewState.brewdateworkerid || ''}
                                    onChange={(e) => setBrewState({ ...brewState, brewdateworkerid: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Original Gravity:</label>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={brewState.originalgravity || ''}
                                    onChange={(e) => setBrewState({ ...brewState, originalgravity: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Batch ID:</label>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={brewState.batchid || ''}
                                    onChange={(e) => setBrewState({ ...brewState, batchid: e.target.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Notes:</label>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={brewState.notes || ''}
                                    onChange={(e) => setBrewState({ ...brewState, notes: e.target.value })}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="button" onClick={handleNewBatchSubmit}>Submit New Batch</button>
                <button type="button" onClick={handleCancel}>Cancel</button>
            </form>
        </div>
    );
};

export default BrewForm;