import React, { createContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [requests, setRequests] = useState([]);
    const [beerBrands, setBeerBrands] = useState([]);
    const [distributors, setDistributors] = useState([]);
    const [runLogs, setRunLogs] = useState([[]]);
    const [allocationLogs, setAllocationLogs] = useState([[]]);
    const [aggregatedProduction, setAggregatedProduction] = useState([]);
    const [aggregatedAllocations, setAggregatedAllocations] = useState([]);
    const [pubInventory, setPubInventory] = useState([]);
    const [allocationLogData, setAllocationLogData] = useState([]);
    const [userEmail, setUserEmail] = useState('');

    const fetchRequests = async () => {
        let { data, error } = await supabase.from('requests').select('*');
        if (error) console.error('Error fetching requests', error);
        else setRequests(data);
    };

    const fetchBeerBrands = async () => {
        const { data, error } = await supabase.from('beerbrands').select('*');
        if (error) {
            console.error('Error fetching beer brands', error);
        } else {
            // Define the order for the first three brands
            const preferredOrder = ["51K IPA", "Mykiss IPA", "Grand Rabbits Cream Ale"];

            // Custom sort function
            const sortedData = data.sort((a, b) => {
                const aIndex = preferredOrder.indexOf(a.brandname);
                const bIndex = preferredOrder.indexOf(b.brandname);

                if (aIndex !== -1 && bIndex !== -1) {
                    // If both are in the preferred order, sort by their predefined order
                    return aIndex - bIndex;
                } else if (aIndex !== -1) {
                    // If only 'a' is in the preferred order, it comes first
                    return -1;
                } else if (bIndex !== -1) {
                    // If only 'b' is in the preferred order, it comes first
                    return 1;
                } else {
                    // If neither are in the preferred order, sort alphabetically
                    return a.brandname.localeCompare(b.brandname);
                }
            });

            // Set the sorted beer brands
            setBeerBrands(sortedData);
        }
    };


    const fetchDistributors = async () => {
        const { data, error } = await supabase.from('distributors').select('*');
        if (error) console.error('Error fetching distributors', error);
        else setDistributors(data);
    };


    const fetchAllocationLogs = async () => {
        let allData = [];
        let from = 0;
        let to = 999;  // Fetch in batches of 1000
        let hasMoreData = true;

        while (hasMoreData) {
            const { data, error } = await supabase
                .from('allocationlog')
                .select(`
                    *,
                    beerbrand:beerbrandid (brandname),
                    distributor:distributorid (distributorname)
                `)
                .order('date', { ascending: false })
                .range(from, to);  // Fetch rows within the range

            if (error) {
                console.error('Error fetching allocation logs', error);
                break;
            } else {
                allData = [...allData, ...data];

                // If we got fewer than 1000 rows, we've fetched all the data
                if (data.length < 1000) {
                    hasMoreData = false;
                } else {
                    from += 1000;  // Move to the next batch
                    to += 1000;
                }
            }
        }

        setAllocationLogs(allData);
    };



    const fetchRunLogs = async () => {
        const { data, error } = await supabase.from('runslog').select(`
            *,
            beerbrand:beerbrandid (brandname)`).order('date', { ascending: false });
        if (error) {
            console.error('Error fetching run logs', error);
        } else {
            setRunLogs(data);
        }
    };

    const fetchPubInventory = async () => {
        const { data, error } = await supabase.from('pub_inventory').select('*');
        if (!error) {
            setPubInventory(data);
        } else {
            console.error('Error fetching pub inventory production', error);
        }
    };

    const fetchAggregatedProduction = async () => {
        const { data, error } = await supabase.from('aggregated_production').select('*');
        if (!error) {
            setAggregatedProduction(data);
        } else {
            console.error('Error fetching aggregated production', error);
        }
    };

    const fetchAggregatedAllocations = async () => {
        const { data, error } = await supabase.from('aggregated_allocations').select('*');
        if (!error) {
            setAggregatedAllocations(data);
        } else {
            console.error('Error fetching aggregated allocations', error);
        }
    };

    const fetchAllocationLogData = async () => {
        let allData = [];
        let from = 0;
        let to = 999;  // Fetch in batches of 1000
        let hasMoreData = true;

        while (hasMoreData) {
            const { data, error } = await supabase
                .from('allocationlog')
                .select(`*`)
                .order('date', { ascending: false })
                .range(from, to);  // Fetch rows within the range

            if (error) {
                console.error('Error fetching allocation logs', error);
                break;
            } else {
                allData = [...allData, ...data];

                // If we got fewer than 1000 rows, we've fetched all the data
                if (data.length < 1000) {
                    hasMoreData = false;
                } else {
                    from += 1000;  // Move to the next batch
                    to += 1000;
                }
            }
        }
        setAllocationLogData(allData);
    };

    const refreshData = () => {
        fetchBeerBrands();
        fetchRunLogs();
        fetchAllocationLogs();
        fetchDistributors();
        fetchAggregatedAllocations();
        fetchAggregatedProduction();
        fetchPubInventory();
        fetchAllocationLogData();
        fetchRequests();
    };

    const refreshBeerBrands = () => {
        fetchBeerBrands();
    };

    const refreshRunLogs = () => {
        fetchRunLogs();
    };

    const refreshAllocationLogs = () => {
        fetchAllocationLogs();
    };

    const refreshDistributors = () => {
        fetchDistributors();
    };

    const refreshAggregatedProduction = () => {
        fetchAggregatedProduction();
    };

    const refreshPubInventory = () => {
        fetchPubInventory();
    };

    const refreshAggregatedAllocations = () => {
        fetchAggregatedAllocations();
    };

    const refreshAllocationLogData = () => {
        fetchAllocationLogData();
    };


    useEffect(() => {
        refreshData();
    }, []);

    return (
        <DataContext.Provider value={{ fetchRunLogs, isAuthenticated, setIsAuthenticated, requests, beerBrands, runLogs, allocationLogs, distributors, allocationLogData, aggregatedProduction, aggregatedAllocations, pubInventory, refreshData, refreshAllocationLogs, refreshBeerBrands, refreshDistributors, refreshRunLogs, refreshAggregatedAllocations, refreshAggregatedProduction, refreshPubInventory, refreshAllocationLogData, setRunLogs, setAllocationLogs, userEmail, setUserEmail }}>
            {children}
        </DataContext.Provider>
    );
};
