import React from "react";
import BRLogo from "../media/BRLogo.png";
import run from "../media/run.png";
import allocation from "../media/allocation.png";
import inventory from "../media/inventory.png";
import brew from "../media/brew.png";
import reports from "../media/reports.png";
import topub from "../media/topub.png";
import feedback from "../media/feedback.png";
import events from "../media/events.png";
import settings from "../media/settings.png"

function NavBar({ activeMode, onModeChange }) {
    const buttonStyle = (mode) => ({
        backgroundColor: activeMode === mode ? '#2b2b2b' : 'transparent',
    });

    return (
        <div className="navBar">
            <img src={BRLogo} className="BRIcon" alt="BR Logo" />
            <div className="navLinks">
                <button
                    onClick={() => onModeChange('brew')}
                    style={buttonStyle('brew')}
                >
                    <img src={brew} alt="Brew" className="brewIcon" />
                </button>
                <button
                    onClick={() => onModeChange('runRecord')}
                    style={buttonStyle('runRecord')}
                >
                    <img src={run} alt="Run Record" className="runIcon" />
                </button>
                <button
                    onClick={() => onModeChange('allocationLog')}
                    style={buttonStyle('allocationLog')}
                >
                    <img src={allocation} alt="Allocation Record" className="allocationIcon" />
                </button>
                {/* <button
                    onClick={() => onModeChange('distroallo')}
                    style={buttonStyle('distroallo')}
                >
                    <img src={allocation} alt="Distro Allo" className="allocationIcon" style={{ transform: 'rotate(180deg)' }}
                    />
                </button> */}
                <button
                    onClick={() => onModeChange('inventory')}
                    style={buttonStyle('inventory')}
                >
                    <img src={inventory} alt="Inventory" className="inventoryIcon" />
                </button>
                <button
                    onClick={() => onModeChange('reports')}
                    style={buttonStyle('reports')}
                >
                    <img src={reports} alt="Reports" className="reportsIcon" />
                </button>
                <button
                    onClick={() => onModeChange('topub')}
                    style={buttonStyle('topub')}
                >
                    <img src={topub} alt="ToPub" className="toPubIcon" />
                </button>
                <button
                    onClick={() => onModeChange('events')}
                    style={buttonStyle('events')}
                >
                    <img src={events} alt="Events" className="eventsIcon" />
                </button>
                <button
                    onClick={() => onModeChange('feedback')}
                    style={buttonStyle('feedback')}
                >
                    <img src={feedback} alt="Feedback" className="feedbackIcon" />
                </button>
                <button
                    onClick={() => onModeChange('settings')}
                    style={buttonStyle('settings')}
                >
                    <img src={settings} alt="Settings" className="settingsIcon" />
                </button>
            </div>
        </div>
    );
}

export default NavBar;