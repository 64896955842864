import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Chip } from '@mui/material';
import { supabase } from '../../supabaseClient';

function NextPackagingWidget() {
    const [nextPackaging, setNextPackaging] = useState(null);
    const [status, setStatus] = useState('');

    useEffect(() => {
        fetchNextPackaging();
    }, []);

    const fetchNextPackaging = async () => {
        const { data, error } = await supabase
            .from('batch')
            .select('batchid, estimatedpackagingdate, beerbrands(brandname)')
            .gt('estimatedpackagingdate', new Date().toISOString())
            .order('estimatedpackagingdate', { ascending: true })
            .limit(1);

        if (error) {
            console.error('Error fetching next packaging data:', error);
            return;
        }

        if (data && data.length > 0) {
            setNextPackaging(data[0]);
            const daysLeft = calculateDaysLeft(data[0].estimatedpackagingdate);
            if (daysLeft > 7) setStatus('On Schedule');
            else if (daysLeft > 3) setStatus('Upcoming');
            else setStatus('Overdue');
        } else {
            setNextPackaging(null);
        }
    };

    const calculateDaysLeft = (date) => {
        const today = new Date();
        const targetDate = new Date(date);
        return Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Next Packaging</Typography>
                {nextPackaging ? (
                    <>
                        <Typography variant="body1">
                            Date: {new Date(nextPackaging.estimatedpackagingdate).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body1">Batch ID: {nextPackaging.batchid}</Typography>
                        <Typography variant="body1">
                            Beer Brand: {nextPackaging.beerbrands?.brandname ?? 'Unknown'}
                        </Typography>
                    </>
                ) : (
                    <Typography variant="body1">No upcoming packaging scheduled</Typography>
                )}
            </CardContent>
        </Card>
    );
}

export default NextPackagingWidget;
