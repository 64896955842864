// File: src/components/RecentNotesWidget.js

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import { supabase } from '../../supabaseClient';

function RecentNotesWidget() {
    const [recentNotes, setRecentNotes] = useState([]);

    useEffect(() => {
        fetchRecentNotes();
    }, []);

    const fetchRecentNotes = async () => {
        const { data: batchNotes, error: batchError } = await supabase
            .from('batch')
            .select('batchid, notes')
            .not('notes', 'is', null)
            .order('batchid', { ascending: false })
            .limit(5);

        const { data: allocationNotes, error: allocationError } = await supabase
            .from('allocationlog')
            .select('allocationid, allocationnotes')
            .not('allocationnotes', 'is', null)
            .order('allocationid', { ascending: false })
            .limit(5);

        if (batchError || allocationError) {
            console.error('Error fetching recent notes:', batchError || allocationError);
            return;
        }

        const combinedNotes = [
            ...batchNotes.map(note => ({ ...note, type: 'Batch', noteText: note.notes })),
            ...allocationNotes.map(note => ({ ...note, type: 'Allocation', noteText: note.allocationnotes }))
        ].sort((a, b) => (b.batchid || b.allocationid) - (a.batchid || a.allocationid)).slice(0, 5);

        setRecentNotes(combinedNotes);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Recent Notes</Typography>
                <List>
                    {recentNotes.map((note, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={`${note.type} ${note.batchid || note.allocationid}`}
                                secondary={note.noteText}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
}

export default RecentNotesWidget;
