import React, { useState, useEffect, useContext } from 'react';
import { DataContext } from '../contexts/DataContext';
import { supabase } from '../supabaseClient';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        displayName: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [mode, setMode] = useState('login');

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { setIsAuthenticated } = useContext(DataContext);

    useEffect(() => {
        const params = new URLSearchParams(window.location.hash.slice(1));
        const accessToken = params.get('access_token');
        const type = params.get('type');

        if (accessToken && type === 'recovery') {
            setMode('resetPassword');
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => {
            const newState = { ...prevState, [name]: value };
            return newState;
        });
    };

    const fetchEmailByDisplayName = async (displayName) => {
        const { data, error } = await supabase
            .from('profiles')
            .select('email')
            .eq('display_name', displayName)
            .single();

        if (error) {
            console.error('Error fetching email:', error);
            throw new Error("Display name not found. Please check and try again.");
        }
        return data.email;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const { email, password } = formData;

            let userData = email.includes('@') ? { email } : { email: await fetchEmailByDisplayName(email) };

            const { data: loginData, error: loginError } = await supabase.auth.signInWithPassword({
                ...userData,
                password,
            });

            if (loginError) {
                console.error('Login error:', loginError);
                throw loginError;
            }


            const user = loginData.user;
            if (!user.user_metadata?.isApproved) {
                console.warn('User not approved');
                throw new Error("Your account is awaiting approval.");
            }

            setIsAuthenticated(true);
        } catch (error) {
            console.error('Login process error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePasswordReset = async () => {
        setError('');
        setIsLoading(true);
        try {
            const { error } = await supabase.auth.resetPasswordForEmail(formData.email);
            if (error) {
                console.error('Password reset error:', error);
                throw error;
            }
            alert('Password reset email sent.');
            setMode('login');
        } catch (error) {
            console.error('Password reset process error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const { email, password, displayName } = formData;

            const { error } = await supabase.auth.signUp({
                email,
                password,
                options: {
                    data: {
                        display_name: displayName,
                        isApproved: false,
                    },
                },
            });

            if (error) {
                console.error('Sign up error:', error);
                throw error;
            }

            alert('Account created successfully! Your account needs to be approved before you can log in.');
            setMode('login');
        } catch (error) {
            console.error('Sign up process error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewPassword = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        const { newPassword, confirmPassword } = formData;  // Make sure formData includes the email

        if (newPassword !== confirmPassword) {
            console.warn('Passwords do not match');
            setError('Passwords do not match');
            setIsLoading(false);
            return;
        }

        const params = new URLSearchParams(window.location.hash.slice(1));
        const accessToken = params.get('access_token');
        const email = params.get('email');
        const type = params.get('type');


        if (!accessToken || type !== 'recovery') {
            console.error('Invalid or missing token');
            setError('Invalid or missing token');
            setIsLoading(false);
            return;
        }

        if (!email) {
            console.error('Email is required to reset password');
            setError('Email is required to reset password');
            setIsLoading(false);
            return;
        }

        try {
            const { error: verifyError } = await supabase.auth.verifyOtp({
                email,  // Providing the user's email
                token: accessToken,
                type: 'recovery',
                password: newPassword
            });

            if (verifyError) {
                console.error('Password update error:', verifyError);
                setError(verifyError.message);
                setIsLoading(false);
                return;
            }

            const { error: updateError } = await supabase.auth.updateUser({
                email: email,  // Providing the user's email
                password: newPassword
            });

            if (updateError) {
                console.error('Password update error:', updateError);
                setError(updateError.message);
                setIsLoading(false);
                return;
            }

            alert('Password updated successfully!');
            setMode('login');
            window.history.replaceState(null, null, window.location.pathname);  // This will clean the URL without reloading the page

        } catch (error) {
            console.error('Unexpected error in password reset:', error);
            setError('An unexpected error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };



    const renderForm = () => {
        switch (mode) {
            case 'login':
                return (
                    <div className='login-form'>
                        <form onSubmit={handleLogin}>
                            <label htmlFor="email">Email / Display Name:</label>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="password">Password:</label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? 'Logging in...' : 'Log In'}
                            </button>
                            <p>
                                Don't have an account?{' '}
                                <span onClick={() => {
                                    setMode('signup');
                                }} style={{ cursor: 'pointer', color: 'cornflowerblue' }}>
                                    Sign up
                                </span>
                            </p>
                            <p>
                                Forgot your password?{' '}
                                <span onClick={() => {
                                    setMode('resetRequest');
                                }} style={{ cursor: 'pointer', color: 'cornflowerblue' }}>
                                    Reset Password
                                </span>
                            </p>
                        </form>
                    </div>
                );
            case 'signup':
                return (
                    <form onSubmit={handleSignUp}>
                        <label htmlFor="displayName">Display Name</label>
                        <input
                            id="displayName"
                            name="displayName"
                            type="text"
                            value={formData.displayName}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="signupEmail">Email</label>
                        <input
                            id="signupEmail"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="signupPassword">Password</label>
                        <input
                            id="signupPassword"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? 'Signing up...' : 'Sign Up'}
                        </button>
                        <p>
                            Already have an account?{' '}
                            <span onClick={() => {
                                setMode('login');
                            }} style={{ cursor: 'pointer', color: 'cornflowerblue' }}>
                                Log in
                            </span>
                        </p>
                    </form>
                );
            case 'resetRequest':
                return (
                    <div>
                        <label htmlFor="resetEmail">Email</label>
                        <input
                            id="resetEmail"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <button onClick={handlePasswordReset} disabled={isLoading}>
                            {isLoading ? 'Sending...' : 'Send Reset Link'}
                        </button>
                        <p>
                            Remembered your password?{' '}
                            <span onClick={() => {
                                setMode('login');
                            }} style={{ cursor: 'pointer', color: 'cornflowerblue' }}>
                                Go back to Login
                            </span>
                        </p>
                    </div>
                );
            case 'resetPassword':
                return (
                    <form onSubmit={handleNewPassword}>
                        <label htmlFor="newPassword">New Password</label>
                        <input
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            value={formData.newPassword}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? 'Resetting...' : 'Reset Password'}
                        </button>
                    </form>
                );
            default:
                console.warn('Unknown mode:', mode);
                return null;
        }
    };

    return (
        <div className="login-page">
            {renderForm()}
            {error && <p role="alert" className="error-message">{error}</p>}
        </div>
    );
};

export default Login;