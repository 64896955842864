import React, { useContext, useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';
import Papa from 'papaparse';
import '../InputAllocation.css';

function InputAllocation() {
    const { refreshData, beerBrands, distributors } = useContext(DataContext);
    const [error, setError] = useState('');
    const [selectedBeerBrand, setSelectedBeerBrand] = useState('');
    const [selectedDistributor, setSelectedDistributor] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [totalCases, setTotalCases] = useState('');
    const [totalHalfBarrels, setTotalHalfBarrels] = useState('');
    const [totalQuarterBarrels, setTotalQuarterBarrels] = useState('');
    const [totalSixthBarrels, setTotalSixthBarrels] = useState('');
    const [inputting, setInputting] = useState(false);
    const [notes, setNotes] = useState('');
    const [inputMode, setInputMode] = useState('single');
    const [batchEntries, setBatchEntries] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // CSV Import States
    const [csvData, setCsvData] = useState([]);
    const [currentCsvIndex, setCurrentCsvIndex] = useState(0);
    const [csvYear, setCsvYear] = useState(new Date().getFullYear());
    const [showYearInput, setShowYearInput] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const fileInputRef = useRef(null);
    const [rawCsvText, setRawCsvText] = useState(''); // New: for raw CSV row display

    useEffect(() => {
        const closeDropdown = (e) => {
            if (isDropdownOpen && !e.target.closest('.new-allocation-button')) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [isDropdownOpen]);

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectOption = (mode) => {
        handleInputModeChange(mode);
        setInputting(true);
        setIsDropdownOpen(false);
    };

    const toggleInputting = () => {
        setInputting(!inputting);
        setIsDropdownOpen(false);
    };

    const handleInputModeChange = (mode) => {
        setInputMode(mode);
        setBatchEntries([]);
        setIsDropdownOpen(false);
    };

    const resetForm = () => {
        setTotalCases('');
        setTotalHalfBarrels('');
        setTotalQuarterBarrels('');
        setTotalSixthBarrels('');
        setNotes('');
    };

    const handleAddToBatch = () => {
        const newEntry = {
            distributorid: selectedDistributor,
            beerbrandid: selectedBeerBrand,
            casesallocated: parseInt(totalCases) || 0,
            halfbarrelsallocated: parseInt(totalHalfBarrels) || 0,
            quarterbarrelsallocated: parseInt(totalQuarterBarrels) || 0,
            sixthbarrelsallocated: parseInt(totalSixthBarrels) || 0,
            allocationnotes: notes || ''
        };
        setBatchEntries([...batchEntries, newEntry]);
        resetForm();
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            complete: (result) => {
                if (result.data && Array.isArray(result.data)) {
                    const parsedData = result.data.filter(row => {
                        return typeof row === 'object' && Object.values(row).some(value => value !== "");
                    });
                    if (parsedData.length > 0) {
                        setCsvData(parsedData);
                        setCurrentCsvIndex(0);
                        setShowYearInput(true);
                    } else {
                        setError("No valid data found in the CSV file.");
                    }
                } else {
                    setError("Failed to parse CSV file. Please check the file format.");
                }
            },
            header: true,
            error: (error) => {
                setError("Error parsing CSV file: " + error.message);
            }
        });
    };

    const handleYearSubmit = () => {
        setShowYearInput(false);
        setIsImporting(true);
        loadCsvRow(currentCsvIndex);  // Start loading from the first CSV row
    };

    const loadCsvRow = (index) => {
        if (index >= csvData.length) {
            finishImport();
            return;
        }

        const row = csvData[index];
        if (typeof row !== 'object') {
            setError("Error loading CSV row: Invalid data format");
            handleNextCsvRow();
            return;
        }

        setRawCsvText(JSON.stringify(row));  // New: Show raw CSV data for verification

        // Check if date is valid
        const parsedDate = parseDate(row.date, csvYear);
        if (!parsedDate) {
            setError(`Invalid date in row ${index + 1}. Skipping row.`);
            handleNextCsvRow();  // Skip this row and continue
            return;
        }

        const distributor = distributors.find(d => d.distributorname === row.distributorid);
        const beerBrand = beerBrands.find(b => b.brandname === row.beerbrandid);

        setSelectedDistributor(distributor ? distributor.distributorid : '');
        setSelectedBeerBrand(beerBrand ? beerBrand.beerbrandid : '');
        setTotalCases(row.casesallocated || '');
        setTotalHalfBarrels(row.halfbarrelsallocated || '');
        setTotalQuarterBarrels(row.quarterbarrelsallocated || '');
        setTotalSixthBarrels(row.sixthbarrelsallocated || '');
        setSelectedDate(parsedDate);
    };

    const parseDate = (dateString, year) => {
        if (!dateString) return null;  // Invalid date string, return null

        const [month, day] = dateString.split('/');
        if (!month || !day || isNaN(month) || isNaN(day)) return null;  // Ensure the date is numeric
        return new Date(year, parseInt(month) - 1, parseInt(day));
    };

    const handleNextCsvRow = () => {
        if (currentCsvIndex < csvData.length - 1) {
            setCurrentCsvIndex(currentCsvIndex + 1);
            loadCsvRow(currentCsvIndex + 1);  // Move to the next row
        } else {
            finishImport();
        }
    };

    const cancelImport = () => {
        setCsvData([]);
        setCurrentCsvIndex(0);
        setShowYearInput(false);
        setIsImporting(false);
        resetForm();
    };

    const finishImport = () => {
        setCsvData([]);
        setCurrentCsvIndex(0);
        setShowYearInput(false);
        setIsImporting(false);
        resetForm();
        toggleInputting();  // This now only closes the form when import is done
    };

    const handleAllocationLogSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const entriesToSubmit = inputMode === 'single' ? [
            {
                date: selectedDate,
                distributorid: selectedDistributor,
                beerbrandid: selectedBeerBrand,
                casesallocated: parseInt(totalCases) || 0,
                halfbarrelsallocated: parseInt(totalHalfBarrels) || 0,
                quarterbarrelsallocated: parseInt(totalQuarterBarrels) || 0,
                sixthbarrelsallocated: parseInt(totalSixthBarrels) || 0,
                allocationnotes: notes || ''
            }
        ] : batchEntries.map(entry => ({ ...entry, date: selectedDate }));

        const { data, error } = await supabase.from('allocationlog').insert(entriesToSubmit);

        if (error) {
            setError('An error occurred while submitting. Please try again. Error: ' + error.message);
        } else {
            resetForm();
            setBatchEntries([]);
            refreshData();

            // Only move to the next CSV row if we're importing
            if (isImporting && currentCsvIndex < csvData.length - 1) {
                handleNextCsvRow();
            } else if (isImporting && currentCsvIndex >= csvData.length - 1) {
                finishImport(); // Close after last CSV row
            } else {
                toggleInputting(); // This closes only for manual input
            }
        }
    };

    // Handle removing an entry from the batch
    const handleRemoveFromBatch = (index) => {
        setBatchEntries(batchEntries.filter((_, i) => i !== index));
    };

    return (
        <div className='input-allocation'>
            {error && <div className='error-message'>{error}</div>}
            {!inputting ? (
                <div className='new-allocation-button' onClick={toggleDropdown}>
                    ☰ New Allocation
                    {isDropdownOpen && (
                        <div className='dropdown-menu'>
                            <button onClick={() => selectOption('single')}>New Allocation</button>
                            <button onClick={() => selectOption('batchByBrand')}>New Batch</button>
                        </div>
                    )}
                </div>
            ) : (
                <div className='allocation-input-form'>
                    <button className="close-button" onClick={toggleInputting}>×</button>
                    {!isImporting && (
                        <>
                            <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileUpload}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                            <button onClick={() => fileInputRef.current.click()}>
                                Import CSV
                            </button>
                        </>
                    )}
                    {showYearInput && (
                        <div className="year-input">
                            <input
                                type="number"
                                value={csvYear}
                                onChange={(e) => setCsvYear(parseInt(e.target.value))}
                                placeholder="Enter year for CSV data"
                            />
                            <button onClick={handleYearSubmit}>Set Year</button>
                            <button onClick={cancelImport}>Cancel Import</button>
                        </div>
                    )}

                    {/* New: Display raw CSV row data for verification */}
                    {isImporting && (
                        <div className="csv-row-display">
                            <h4>Current CSV Row Data:</h4>
                            <pre>{rawCsvText}</pre>
                        </div>
                    )}

                    <div className='input-header'>
                        <DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} className="date-picker" />
                        <select value={selectedBeerBrand} onChange={(e) => setSelectedBeerBrand(e.target.value)} className="select-field">
                            <option value="">Select a Brand</option>
                            {beerBrands.map((brand) => (
                                <option key={brand.beerbrandid} value={brand.beerbrandid}>{brand.brandname}</option>
                            ))}
                        </select>
                        <select value={selectedDistributor} onChange={(e) => setSelectedDistributor(e.target.value)} className="select-field">
                            <option value="">Select a Distributor</option>
                            {distributors.map((distributor) => (
                                <option key={distributor.distributorid} value={distributor.distributorid}>{distributor.distributorname}</option>
                            ))}
                        </select>
                    </div>
                    <div className='input-grid'>
                        <input type="text" inputMode="numeric" pattern="-?\d*" value={totalCases} onChange={(e) => setTotalCases(e.target.value)} placeholder='Cases' className="input-field" />
                        <input type="text" inputMode="numeric" pattern="-?\d*" value={totalHalfBarrels} onChange={(e) => setTotalHalfBarrels(e.target.value)} placeholder='1/2s' className="input-field" />
                        <input type="text" inputMode="numeric" pattern="-?\d*" value={totalQuarterBarrels} onChange={(e) => setTotalQuarterBarrels(e.target.value)} placeholder='1/4s' className="input-field" />
                        <input type="text" inputMode="numeric" pattern="-?\d*" value={totalSixthBarrels} onChange={(e) => setTotalSixthBarrels(e.target.value)} placeholder='1/6s' className="input-field" />
                        <input type="text" value={notes} onChange={(e) => setNotes(e.target.value)} placeholder='Notes' className="input-field notes" />
                    </div>
                    <div className='button-container'>
                        {inputMode !== 'single' && (
                            <button type="button" onClick={handleAddToBatch} className="add-to-batch-button">Add to Batch</button>
                        )}
                        <button className="submit-button" onClick={handleAllocationLogSubmit}>
                            {isImporting ? `Add (${currentCsvIndex + 1}/${csvData.length})` : (inputMode === 'single' ? 'Add Allocation' : 'Submit Batch')}
                        </button>
                        {isImporting && (
                            <>
                                <button type="button" onClick={handleNextCsvRow}>Skip</button>
                                <button type="button" onClick={cancelImport}>Cancel</button>
                            </>
                        )}
                    </div>
                    {inputMode !== 'single' && (
                        <div className='batch-entries'>
                            {batchEntries.map((entry, index) => {
                                const beerBrand = beerBrands.find(b => parseInt(b.beerbrandid) === parseInt(entry.beerbrandid));
                                const distributor = distributors.find(d => parseInt(d.distributorid) === parseInt(entry.distributorid));
                                return (
                                    <div key={index} className='batch-entry'>
                                        <span>
                                            {beerBrand ? beerBrand.brandname : 'Unknown Brand'}/
                                            {distributor ? distributor.distributorname : 'Unknown Distributor'}
                                        </span>
                                        <span>Cases: {entry.casesallocated}, 1/2s: {entry.halfbarrelsallocated},
                                            1/4s: {entry.quarterbarrelsallocated}, 1/6s: {entry.sixthbarrelsallocated}</span>
                                        <button onClick={() => handleRemoveFromBatch(index)} className="remove-entry-button">×</button>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default InputAllocation;
