import { useState } from 'react';
import { styles } from '../styles/brewStyles.js';
import { toISOString, toDate } from '../utils/dateUtils.js'; // Import the utility functions

export const useBrewState = () => {
    const [beerBrands, setBeerBrands] = useState([]);
    const [vessels, setVessels] = useState([]);
    const [brites, setBrites] = useState([]);
    const [allBatches, setAllBatches] = useState([]);
    const [activeBatches, setActiveBatches] = useState([]);
    const [vesselStyles, setVesselStyles] = useState(Array(15).fill(styles.openStyle));
    const [briteStyles, setBriteStyles] = useState(Array(5).fill(styles.openBrite));
    const [selectedVessel, setSelectedVessel] = useState(null);
    const [selectedBrite, setSelectedBrite] = useState(null);
    const [showNewBatchForm, setShowNewBatchForm] = useState(false);
    const [showFullBrite, setShowFullBrite] = useState(false);
    const [selectingBrite, setSelectingBrite] = useState(false);
    const [brewState, setBrewState] = useState({
        batchid: null,
        beerbrandid: null,
        brewdate: null,
        brewdateworkerid: null,
        originalgravity: null,
        fermentationvesselid: null,
        finalgravity: null,
        yield: null,
        estimatedpackagingdate: null,
        status: null,
        dryhopdate: null,
        dryhopdateworkerid: null,
        crashdate: null,
        crashdateworkerid: null,
        britetankids: null,
        notes: null
    });

    const updateBrewState = (newState) => {
        setBrewState(prevState => ({
            ...prevState,
            ...newState,
            brewdate: newState.brewdate ? toISOString(newState.brewdate) : prevState.brewdate,
            dryhopdate: newState.dryhopdate ? toISOString(newState.dryhopdate) : prevState.dryhopdate,
            crashdate: newState.crashdate ? toISOString(newState.crashdate) : prevState.crashdate,
            estimatedpackagingdate: newState.estimatedpackagingdate ? toISOString(newState.estimatedpackagingdate) : prevState.estimatedpackagingdate,
        }));
    };

    return {
        beerBrands, setBeerBrands,
        vessels, setVessels,
        brites, setBrites,
        activeBatches, setActiveBatches,
        allBatches, setAllBatches,
        vesselStyles, setVesselStyles,
        briteStyles, setBriteStyles,
        selectedVessel, setSelectedVessel,
        selectedBrite, setSelectedBrite,
        showNewBatchForm, setShowNewBatchForm,
        showFullBrite, setShowFullBrite,
        selectingBrite, setSelectingBrite,
        brewState, setBrewState, updateBrewState
    };
};
