import React from 'react';

const BrewSelection = ({ vesselStyles, briteStyles, onSelectVessel, onSelectBrite, activeBatches, beerBrands }) => {
    const renderVesselButton = (id, type = 'FV') => {
        // Find the active batch in the vessel
        let beerName = "";
        if (type === 'FV') {
            // Only consider the fermentation vessel if the batch is not already in a brite tank
            const activeBatch = activeBatches.find(batch => batch.fermentationvesselid === id && (!batch.britetankids || batch.britetankids.length === 0));
            beerName = activeBatch ? beerBrands.find(beer => beer.beerbrandid === activeBatch.beerbrandid)?.brandname : 'Empty';
        } else {
            // For brite tanks, check the brite tank IDs
            const activeBatch = activeBatches.find(batch => batch.britetankids && batch.britetankids.includes(id));
            beerName = activeBatch ? beerBrands.find(beer => beer.beerbrandid === activeBatch.beerbrandid)?.brandname : 'Empty';
        }


        if (beerName === 'Empty' || beerName === "") {
            return (
                <button
                    key={`${type}${id}`}
                    onClick={() => type === 'FV' ? onSelectVessel(id) : onSelectBrite(id)}
                    style={type === 'FV' ? vesselStyles[id] : briteStyles[id]}
                    className="vessel-button"
                >
                    {`${type}${id}`}
                </button>
            );
        }
        beerName = beerName ? beerName.substring(0, 7) : beerName;
        return (
            <button
                key={`${type}${id}`}
                onClick={() => type === 'FV' ? onSelectVessel(id) : onSelectBrite(id)}
                style={type === 'FV' ? vesselStyles[id] : briteStyles[id]}
                className="vessel-button"
            >
                {`${type}${id}`}<br />{beerName}
            </button>
        );
    };


    return (
        <div className="left-pane">
            <div className="button-grid">
                <div className="button-row">
                    {[1, 2, 3, 4, 5].map(id => renderVesselButton(id, 'FV'))}
                    {renderVesselButton(1, 'BT')}
                </div>
                <div className="button-row">
                    {[6, 7, 8, 9, 10].map(id => renderVesselButton(id, 'FV'))}
                    {renderVesselButton(2, 'BT')}
                </div>
                <div className="button-row">
                    {renderVesselButton(11, 'FV')}
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                    {renderVesselButton(3, 'BT')}
                </div>
                <div className="button-row">
                    {renderVesselButton(13, 'FV')}
                    {renderVesselButton(4, 'BT')}
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                </div>
                <div className="button-row">
                    {renderVesselButton(14, 'FV')}
                    {renderVesselButton(12, 'FV')}
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                    <button className="invisible-button"></button>
                </div>
            </div>
        </div>
    );
};

export default BrewSelection;
