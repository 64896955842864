// RequestBoard.js
import React, { useContext } from 'react';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';


function RequestBoard() {
    const { requests, refreshData } = useContext(DataContext);

    const deleteRequest = async (requestId) => {
        if (window.confirm("Are you sure you want to delete this request?")) {
            const { error } = await supabase.from('requests').delete().match({ id: requestId });
            if (error) console.error('Error deleting request', error);
            else refreshData(); // Refresh list after deletion
        }
    };

    const sortedRequests = requests.sort((a, b) => new Date(b.date) - new Date(a.date));


    return (
        <div className='feedback-Logs'>
            {sortedRequests.map((request, index) => (
                <div className='row' key={index}>
                    <button onClick={() => deleteRequest(request.id)}>X</button>
                    <div className='log' key={index}>
                        <p className='log-date ellipsis'>{request.date}</p>
                        <div className='description-section'>
                            <p className='log-description ellipsis'>{request.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default RequestBoard;
