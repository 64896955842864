import React, { useRef, useContext, useState, useEffect } from 'react';
import { DataContext } from '../contexts/DataContext';
import { supabase } from '../supabaseClient';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { WithContext as ReactTags } from 'react-tag-input';


function RunLogs() {
    const { fetchRunLogs } = useContext(DataContext);
    const { runLogs, setRunLogs } = useContext(DataContext);
    const { beerBrands } = useContext(DataContext);
    const [editStates, setEditStates] = useState({});
    const [collapsedMonths, setCollapsedMonths] = useState({});
    const [filters, setFilters] = useState({ dateRange: [null, null], beerBrand: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'descending' });
    const dropdownRef = useRef(null);
    const containerRef = useRef(null);
    const KeyCodes = {
        comma: 188,
        enter: 13,
        space: 32,
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

    const columnConfig = {
        beerbrand: 'Beer Brand',
        date: 'Date',
        runid: 'Run ID',
        batchids: 'Batch',
        batchsize: 'Batch Size',
        botc: 'BOTC',
        totalcases: 'Total',
        totalhalfbarrels: 'Half Barrels',
        totalquarterbarrels: 'Quarter Barrels',
        totalsixthbarrels: 'Sixth Barrels',
        runefficiency: 'Run Efficiency',
        runnotes: 'Run Notes',
    };

    useEffect(() => {
        const filteredLogs = runLogs.map(log => ({
            ...log,
            date: new Date(log.date),
        }));
        setEditStates(filteredLogs.reduce((acc, log) => {
            acc[log.runid] = { ...log, isEditing: {}, isSelected: {} };
            return acc;
        }, {}));
    }, [runLogs]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Close all dropdowns
                const newState = { ...editStates };
                Object.keys(newState).forEach(logId => {
                    newState[logId].isEditing.beerbrand = false;
                });
                setEditStates(newState);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [editStates]);

    const applyFiltersAndSort = (logs) => {
        let filteredLogs = logs;

        // Apply date range filter
        if (filters.dateRange[0] && filters.dateRange[1]) {
            filteredLogs = filteredLogs.filter(log => {
                const logDate = new Date(log.date);
                return logDate >= filters.dateRange[0] && logDate <= filters.dateRange[1];
            });
        }

        // Apply beer brand filter
        if (filters.beerBrand) {
            filteredLogs = filteredLogs.filter(log => log.beerbrandid === parseInt(filters.beerBrand));
        }

        // Apply sorting
        if (sortConfig.key) {
            filteredLogs.sort((a, b) => {
                if (sortConfig.key === 'date') {
                    // First, compare by date
                    const dateComparison = new Date(a.date) - new Date(b.date);
                    if (dateComparison !== 0) {
                        return sortConfig.direction === 'ascending' ? dateComparison : -dateComparison;
                    }
                    // If dates are equal, compare by run ID
                    return sortConfig.direction === 'ascending' ?
                        (a.runid - b.runid) :
                        (b.runid - a.runid);
                }
                // For other keys, keep the existing sorting logic
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return filteredLogs;
    };


    const toggleEdit = (logId, field) => {
        if (field === 'runid' || field === 'runefficiency') return;
        const newState = { ...editStates };
        newState[logId].isEditing[field] = !newState[logId].isEditing[field];
        setEditStates(newState);

        // If entering edit mode for beerbrand, focus and click the select element
        if (field === 'beerbrand' && newState[logId].isEditing[field]) {
            setTimeout(() => {
                const selectElement = document.querySelector(`#beerbrand-select-${logId}`);
                if (selectElement) {
                    selectElement.focus();
                    selectElement.click();
                }
            }, 0);
        }
    };

    const groupLogsByMonth = (logs) => {
        return logs.reduce((groups, log) => {
            const date = new Date(log.date);
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 2).padStart(2, '0')}`;
            if (!groups[monthYear]) {
                groups[monthYear] = [];
            }
            groups[monthYear].push(log);
            return groups;
        }, {});
    };

    const toggleMonth = (monthYear) => {
        setCollapsedMonths(prev => ({
            ...prev,
            [monthYear]: !prev[monthYear]
        }));
    };

    const toggleSelect = (logId, field) => {
        const newState = { ...editStates };
        Object.keys(newState).forEach(id => {
            Object.keys(newState[id].isSelected).forEach(f => {
                newState[id].isSelected[f] = false;
            });
        });
        newState[logId].isSelected[field] = true;
        setEditStates(newState);
    };

    const handleAddition = (logId, tag) => {
        const newState = { ...editStates };
        const currentBatchIds = newState[logId].batchids || [];
        if (!currentBatchIds.includes(parseInt(tag.id))) {
            newState[logId].batchids = [...currentBatchIds, parseInt(tag.id)];
            setEditStates(newState);
        }
    };

    const handleDelete = (logId, i) => {
        const newState = { ...editStates };
        newState[logId].batchids = newState[logId].batchids.filter((_, index) => index !== i);
        setEditStates(newState);
    };

    const handleChange = async (value, logId, field) => {
        const newState = { ...editStates };
        if (field === 'batchids') {
            newState[logId][field] = value.map(tag => parseInt(tag.id)).filter(id => !isNaN(id));
        } else {
            newState[logId][field] = value;
        }
        setEditStates(newState);
        if (field === 'beerbrand' || field === 'date') {
            await handleBlur(logId, field);
        }
    };

    const calculateRunEfficiency = (cases, halves, quarters, sixths, batchSize) => {
        cases = parseInt(cases) || 0;
        halves = parseInt(halves) || 0;
        quarters = parseInt(quarters) || 0;
        sixths = parseInt(sixths) || 0;
        batchSize = parseFloat(batchSize) || 0;
        return ((cases * 288 + halves * 1984 + quarters * 992 + sixths * 661.3) / (batchSize * 3968)) || 0;
    };

    const handleInputBlur = async (logId, field) => {
        setTimeout(() => {
            if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
                const log = editStates[logId];
                const updatedValue = log[field];
                const updateField = field;

                let updates = { [updateField]: updatedValue };

                supabase.from('runslog').update(updates).match({ runid: logId }).then(({ error }) => {
                    if (!error) {
                        toggleEdit(logId, field);
                        fetchRunLogs();
                    } else {
                        console.error('Error updating log', error);
                    }
                });
            }
        }, 0);
    };

    const handleBlur = async (logId, field) => {
        const log = editStates[logId];
        const updatedValue = field === 'date' ? log[field].toISOString().split('T')[0] : log[field];
        const updateField = field === 'beerbrand' ? 'beerbrandid' : field;

        let updates = { [updateField]: updatedValue };
        if (['totalcases', 'totalhalfbarrels', 'totalquarterbarrels', 'totalsixthbarrels', 'batchsize'].includes(field)) {
            const efficiency = calculateRunEfficiency(log.totalcases, log.totalhalfbarrels, log.totalquarterbarrels, log.totalsixthbarrels, log.batchsize);
            updates.runefficiency = efficiency;
        }

        const { error } = await supabase.from('runslog').update(updates).match({ runid: logId });
        if (!error) {
            toggleEdit(logId, field);
            fetchRunLogs();
        } else {
            console.error('Error updating log', error);
        }
    };

    const handleKeyPress = async (e, logId, field) => {

        if (e.key === 'Enter') {
            e.preventDefault();
            // If the input is empty, save and exit edit mode
            await handleBlur(logId, field);
        }
    };

    const deleteLog = async (logId) => {
        if (window.confirm("Are you sure you want to delete this log?")) {
            const { error } = await supabase.from('runslog').delete().match({ runid: logId });
            if (!error) {
                setRunLogs(runLogs.filter(log => log.runid !== logId));
            } else {
                console.error('Error deleting log', error);
            }
        }
    };

    const renderLabelRow = () => (
        <div className='label-row'>
            <div className="delete-cell"></div>
            {Object.keys(columnConfig).map(field => (
                <div key={field} className="log-cell label-cell">
                    <p className="log-content ellipsis">{columnConfig[field]}</p>
                </div>
            ))}
        </div>
    );

    const renderLogRow = (log) => (
        <div className='run-row' key={log.runid}>
            <div className="delete-cell" onClick={() => deleteLog(log.runid)}>X</div>
            {Object.keys(columnConfig).map(field => editStates[log.runid]?.isEditing[field] ? (
                field === 'date' ? (
                    <div className='date-picker'>
                        <DatePicker
                            key={field}
                            selected={new Date(editStates[log.runid][field].getTime() + editStates[log.runid][field].getTimezoneOffset() * 60000)}
                            onChange={(date) => handleChange(date, log.runid, field)}
                            onBlur={() => handleBlur(log.runid, field)}
                            onKeyPress={(e) => handleKeyPress(e, log.runid, field)}
                            autoFocus
                            popperPlacement="top-end"
                            className="date-picker"
                            dateFormat="yyyy-MM-dd"
                        />
                    </div>
                ) : field === 'beerbrand' ? (
                    <div ref={dropdownRef}>
                        <select
                            id={`beerbrand-select-${log.runid}`}
                            key={field}
                            value={editStates[log.runid][field]}
                            onChange={(e) => handleChange(e.target.value, log.runid, field)}
                            onBlur={() => handleBlur(log.runid, field)}
                            onKeyDown={(e) => handleKeyPress(e, log.runid, field)}
                            autoFocus
                            className="beerbrand-dropdown"
                        >
                            <option value="">Select a Brand</option>
                            {beerBrands.map((brand) => (
                                <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                    {brand.brandname}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : field === 'batchids' ? (
                    <div ref={containerRef} className="batch-tags-container">
                        <ReactTags
                            tags={editStates[log.runid].batchids.map(id => ({ id: id.toString(), text: id.toString() }))}
                            handleDelete={(i) => handleDelete(log.runid, i)}
                            handleAddition={(tag) => handleAddition(log.runid, tag)}
                            delimiters={delimiters}
                            handleInputBlur={() => handleInputBlur(log.runid, field)}
                            inputFieldPosition="inline"
                            autocomplete
                            classNames={{
                                tags: 'ReactTags__tags',
                                tagInput: 'ReactTags__tagInput',
                                tagInputField: 'ReactTags__tagInputField',
                                selected: 'ReactTags__selected',
                                tag: 'ReactTags__tag',
                                remove: 'ReactTags__remove',
                            }}
                        />
                    </div>
                ) : (
                    <input
                        key={field}
                        className='log-input'
                        type="text"
                        value={editStates[log.runid][field]}
                        onChange={(e) => handleChange(e.target.value, log.runid, field)}
                        onBlur={() => handleBlur(log.runid, field)}
                        onKeyDown={(e) => handleKeyPress(e, log.runid, field)}
                        autoFocus
                    />
                )
            ) : (
                <p key={field} className={`log-cell ellipsis ${editStates[log.runid]?.isSelected[field] ? 'selected' : ''}`}
                    onClick={() => toggleSelect(log.runid, field)}
                    onDoubleClick={() => toggleEdit(log.runid, field)}>
                    {field === 'batchids' && log.batchids ? log.batchids.join(', ') :
                        field === 'beerbrand' ? log.beerbrand?.brandname || 'Unknown' :
                            field === 'runefficiency' ? Math.trunc(log[field] * 100) + '%' :
                                field === 'date' ? new Date(log[field] + 'T00:00:00Z').toISOString().split('T')[0] :
                                    log[field]}
                </p>
            ))}
        </div>
    );

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleDateRangeChange = (dates) => {
        const [start, end] = dates;
        setFilters({ ...filters, dateRange: [start, end] });
    };

    const handleSortChange = (key) => {
        let direction = 'descending';
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const groupedLogs = groupLogsByMonth(applyFiltersAndSort(runLogs));

    if (runLogs.length > 1) {
        return (
            <div className='Logs' style={{ overflowX: 'auto' }}>
                <div className="filters">
                    <DatePicker
                        selectsRange
                        startDate={filters.dateRange[0]}
                        endDate={filters.dateRange[1]}
                        onChange={handleDateRangeChange}
                        isClearable={true}
                        placeholderText="Select a date range"
                    />
                    <select name="beerBrand" value={filters.beerBrand} onChange={handleFilterChange}>
                        <option value="">All Brands</option>
                        {beerBrands.map((brand) => (
                            <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                {brand.brandname}
                            </option>
                        ))}
                    </select>
                    <button onClick={() => handleSortChange('date')}>Sort by Date</button>
                    <button onClick={() => handleSortChange('batchsize')}>Sort by Batch Size</button>
                </div>
                <div className="log-container">
                    {renderLabelRow()}
                    {Object.entries(groupedLogs).map(([monthYear, logs]) => (
                        <div key={monthYear} className="month-group">
                            <div
                                className="month-header"
                                onClick={() => toggleMonth(monthYear)}
                            >
                                <span>{collapsedMonths[monthYear] ? '▼' : '▲'}</span>
                                <p>{new Date(monthYear).toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
                            </div>
                            {!collapsedMonths[monthYear] && logs.map(renderLogRow)}
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return (<div></div>);
    }
}

export default RunLogs;

