// File: src/components/TankUtilizationWidget.js

import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Tabs, Tab, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { supabase } from '../../supabaseClient';

function TankUtilizationWidget() {
    const [utilizationData, setUtilizationData] = useState([]);
    const [timeFrame, setTimeFrame] = useState('week');

    useEffect(() => {
        fetchUtilizationData(timeFrame);
    }, [timeFrame]);

    const fetchUtilizationData = async (period) => {
        let query = supabase
            .from('fermentationvessels')
            .select('status, capacity, batchid');

        const { data: vesselsData, error: vesselsError } = await query;

        if (vesselsError) {
            console.error('Error fetching tank utilization data:', vesselsError);
            return;
        }

        // Filter out any vessels with null batchid
        const validVesselsData = vesselsData.filter(vessel => vessel.batchid !== null);

        // Fetch corresponding batch dates using the batch IDs
        const batchIds = validVesselsData.map(vessel => vessel.batchid);
        const { data: batchData, error: batchError } = await supabase
            .from('batch')
            .select('batchid, brewdate')
            .in('batchid', batchIds);

        if (batchError) {
            console.error('Error fetching batch data:', batchError);
            return;
        }

        // Map the brewdate from batchData to the vesselsData
        const vesselsWithDates = validVesselsData.map(vessel => {
            const batch = batchData.find(b => b.batchid === vessel.batchid);
            return {
                ...vessel,
                brewdate: batch ? new Date(batch.brewdate) : null,
            };
        });

        // Filter data based on the selected time frame
        const filteredData = filterByTimeFrame(vesselsWithDates, period);

        const processedData = processUtilizationData(filteredData);
        setUtilizationData(processedData);
    };

    const filterByTimeFrame = (data, period) => {
        const now = new Date();
        return data.filter(vessel => {
            if (!vessel.brewdate) return false;  // Skip if brewdate is null
            switch (period) {
                case 'week':
                    return vessel.brewdate >= new Date(now.setDate(now.getDate() - 7));
                case 'month':
                    return vessel.brewdate >= new Date(now.setMonth(now.getMonth() - 1));
                case 'ytd':
                    return vessel.brewdate >= new Date(now.getFullYear(), 0, 1);
                case 'year':
                    return vessel.brewdate >= new Date(now.setFullYear(now.getFullYear() - 1));
                case '5year':
                    return vessel.brewdate >= new Date(now.setFullYear(now.getFullYear() - 5));
                case 'max':
                default:
                    return true;
            }
        });
    };

    const processUtilizationData = (data) => {
        const totalCapacity = data.reduce((total, vessel) => total + vessel.capacity, 0);
        const occupiedCapacity = data.filter(vessel => vessel.status === 'Occupied').reduce((total, vessel) => total + vessel.capacity, 0);
        const availableCapacity = totalCapacity - occupiedCapacity;

        return [
            { name: 'Occupied', value: (occupiedCapacity / totalCapacity) * 100 },
            { name: 'Available', value: (availableCapacity / totalCapacity) * 100 },
        ];
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Tank Utilization</Typography>
                <Tabs
                    value={timeFrame}
                    onChange={(event, newValue) => setTimeFrame(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Week" value="week" />
                    <Tab label="Month" value="month" />
                    <Tab label="YTD" value="ytd" />
                    <Tab label="Year" value="year" />
                    <Tab label="5 Year" value="5year" />
                    <Tab label="Max" value="max" />
                </Tabs>
                <Box height={300}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={utilizationData}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="value" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </CardContent>
        </Card>
    );
}

export default TankUtilizationWidget;
