import { createClient } from '@supabase/supabase-js';

const isLocalhost = typeof window !== 'undefined' && window.location.hostname === 'localhost';

// Configuration for the test (local) database
const testSupabaseUrl = 'https://smwvzpuhdnfadwipaiuk.supabase.co'; // Replace with your test Supabase URL
const testSupabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNtd3Z6cHVoZG5mYWR3aXBhaXVrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE0NTA1MTIsImV4cCI6MjA0NzAyNjUxMn0.HmRLO3h8JRmgYOkKx-cJ7DKbOaBO4khv_FSr_9hDXRA';     // Replace with your test Supabase key

// Configuration for the production database
const prodSupabaseUrl = 'https://ptanktaivsjwlzppqnyp.supabase.co'; 
const prodSupabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB0YW5rdGFpdnNqd2x6cHBxbnlwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE3MzkyMzcsImV4cCI6MjAxNzMxNTIzN30.87M3CmLnMHqNh7p33EELXHIXwzrY8FwwZxPOnUB-DzE';

// Select database configuration based on environment
const supabaseUrl = isLocalhost ? testSupabaseUrl : prodSupabaseUrl;
const supabaseKey = isLocalhost ? testSupabaseKey : prodSupabaseKey;

export const supabase = createClient(supabaseUrl, supabaseKey);
