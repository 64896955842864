import moment from 'moment';

export const toISOString = (date) => date instanceof Date ? date.toISOString().split('T')[0] : date;
export const toDate = (isoString) => isoString ? new Date(isoString) : null;

export const standardizeDateHandling = {
    // Convert a date to a standardized format for storage
    toStorageFormat: (date) => {
        return moment(date).utc().format('YYYY-MM-DD');
    },

    // Convert a date from storage format to display format
    fromStorageFormat: (dateString) => {
        return moment.utc(dateString).startOf('day').toDate();
    },

    // Format a date for display
    formatForDisplay: (date) => {
        return moment(date).format('MM/DD/YYYY');
    },

    // Handle initial date selection in forms
    handleInitialDateSelection: (date) => {
        // Add one day to the selected date to compensate for the off-by-one error
        return moment(date).add(1, 'days').toDate();
    }
};