import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Select, MenuItem } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { supabase } from '../../supabaseClient';

function AllocationWidget() {
    const [allocationData, setAllocationData] = useState([]);
    const [totalAllocation, setTotalAllocation] = useState(0);
    const [comparisonPeriod, setComparisonPeriod] = useState('month'); // Example period toggle

    useEffect(() => {
        fetchAllocationData(comparisonPeriod);
    }, [comparisonPeriod]);

    const fetchAllocationData = async (period) => {
        const { data, error } = await supabase
            .from('allocationlog')
            .select('date, casesallocated, halfbarrelsallocated, quarterbarrelsallocated, sixthbarrelsallocated')
            .order('date', { ascending: true });

        if (error) {
            console.error('Error fetching allocation data:', error);
            return;
        }

        const processedData = processAllocationData(data, period);
        setAllocationData(processedData);
        setTotalAllocation(processedData.reduce((sum, item) => sum + item.totalAllocation, 0));
    };

    const processAllocationData = (data, period) => {
        const now = new Date();
        const filteredData = data.filter(item => {
            const date = new Date(item.date);
            switch (period) {
                case 'week':
                    return date >= new Date(now.setDate(now.getDate() - 7));
                case 'month':
                    return date >= new Date(now.setMonth(now.getMonth() - 1));
                case 'year':
                    return date >= new Date(now.setFullYear(now.getFullYear() - 1));
                default:
                    return true;
            }
        });

        const groupedData = filteredData.reduce((acc, cur) => {
            const date = new Date(cur.date);
            const key = `${date.getFullYear()}-${date.getMonth() + 1}`;
            if (!acc[key]) {
                acc[key] = { date: key, totalAllocation: 0 };
            }
            acc[key].totalAllocation +=
                cur.casesallocated +
                cur.halfbarrelsallocated * 15.5 +
                cur.quarterbarrelsallocated * 7.75 +
                cur.sixthbarrelsallocated * 5.16;
            return acc;
        }, {});

        return Object.values(groupedData);
    };

    const handlePeriodChange = (event) => {
        setComparisonPeriod(event.target.value);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Total Allocation</Typography>
                <Select
                    value={comparisonPeriod}
                    onChange={handlePeriodChange}
                    displayEmpty
                >
                    <MenuItem value="week">This Week</MenuItem>
                    <MenuItem value="month">This Month</MenuItem>
                    <MenuItem value="year">This Year</MenuItem>
                </Select>
                <Typography variant="h4">{totalAllocation.toFixed(2)} units</Typography>
                <Box height={300}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={allocationData}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="totalAllocation" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </CardContent>
        </Card>
    );
}

export default AllocationWidget;
