import React, { useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';


const Inventory = () => {
    const PUB_ID = 11;
    const INVENTORY_BALANCE_ID = 7;
    const { beerBrands, refreshData } = useContext(DataContext);
    const [selectedBeerBrands, setSelectedBeerBrands] = useState([]);
    const { aggregatedProduction, refreshAggregatedProduction } = useContext(DataContext);
    const { aggregatedAllocations, refreshAggregatedAllocations } = useContext(DataContext);
    const { pubInventory, refreshPubInventory } = useContext(DataContext);
    const { allocationLogData, refreshAllocationLogData } = useContext(DataContext);
    const [isReconciliationMode, setIsReconciliationMode] = useState(false);
    const [editableInventory, setEditableInventory] = useState({});
    const [editablePub, setEditablePub] = useState({});
    const [pendingChanges, setPendingChanges] = useState({}); // Track changes


    useEffect(() => {
        refreshData();
        updateBeerBrandsWithInventory(beerBrands, aggregatedProduction, aggregatedAllocations, pubInventory);
    }, []);

    useEffect(() => {
        updateBeerBrandsWithInventory(beerBrands, aggregatedProduction, aggregatedAllocations, pubInventory);
        if (isReconciliationMode) {
            initializeEditableInventory();
            initializeEditablePub();
        }
    }, [isReconciliationMode]);

    const updateBeerBrandsWithInventory = (beerBrands, production, allocations, pub) => {
        if (!isReconciliationMode) {
            const filteredBeerBrands = beerBrands.filter(beer => {
                const productionEntry = production.find(p => p.beerbrandid === beer.beerbrandid);
                const allocationEntry = allocations.find(a => a.beerbrandid === beer.beerbrandid);
                const pubEntry = pub.find(u => u.beerbrandid === beer.beerbrandid);

                // Retrieve production quantities or default to 0 if not available
                const totalCasesProduced = productionEntry?.totalcases || 0;
                const totalHalfBarrelsProduced = productionEntry?.totalhalfbarrels || 0;
                const totalQuarterBarrelsProduced = productionEntry?.totalquarterbarrels || 0;
                const totalSixthBarrelsProduced = productionEntry?.totalsixthbarrels || 0;

                // Retrieve allocation quantities or default to 0 if not available
                const totalCasesAllocated = allocationEntry?.totalcases || 0;
                const totalHalfBarrelsAllocated = allocationEntry?.totalhalfbarrels || 0;
                const totalQuarterBarrelsAllocated = allocationEntry?.totalquarterbarrels || 0;
                const totalSixthBarrelsAllocated = allocationEntry?.totalsixthbarrels || 0;


                // Retrieve allocation quantities or default to 0 if not available
                const totalCasesPub = pubEntry?.totalcases || 0;
                const totalHalfBarrelsPub = pubEntry?.totalhalfbarrels || 0;
                const totalQuarterBarrelsPub = pubEntry?.totalquarterbarrels || 0;
                const totalSixthBarrelsPub = pubEntry?.totalsixthbarrels || 0;

                // Calculate remainings
                const remainingCases = totalCasesProduced - totalCasesAllocated + totalCasesPub;
                const remainingHalfBarrels = totalHalfBarrelsProduced - totalHalfBarrelsAllocated + totalHalfBarrelsPub;
                const remainingQuarterBarrels = totalQuarterBarrelsProduced - totalQuarterBarrelsAllocated + totalQuarterBarrelsPub;
                const remainingSixthBarrels = totalSixthBarrelsProduced - totalSixthBarrelsAllocated + totalSixthBarrelsPub;


                // Check if any remaining is positive
                return remainingCases > 0 || remainingHalfBarrels > 0 || remainingQuarterBarrels > 0 || remainingSixthBarrels > 0;
            });


            setSelectedBeerBrands(filteredBeerBrands);
        }
        else {
            setSelectedBeerBrands(beerBrands);
        }
    };

    const calculateAllocationTotals = (beerId, distributorId, pub) => {
        // Ensure IDs are compared as integers
        const beerIdInt = parseInt(beerId, 10);
        const distributorIdInt = parseInt(distributorId, 10);
        let totals = {};

        if (distributorIdInt === PUB_ID) {
            totals = { pubCases: 0, pubHalfBarrels: 0, pubQuarterBarrels: 0, pubSixthBarrels: 0 };
            const pubEntry = pub.find(a => a.beerbrandid === beerIdInt);
            // Retrieve allocation quantities or default to 0 if not available
            totals.pubCases = pubEntry?.totalcases || 0;
            totals.pubHalfBarrels = pubEntry?.totalhalfbarrels || 0;
            totals.pubQuarterBarrels = pubEntry?.totalquarterbarrels || 0;
            totals.pubSixthBarrels = pubEntry?.totalsixthbarrels || 0;
        }
        else {
            totals = { cases: 0, halfBarrels: 0, quarterBarrels: 0, sixthBarrels: 0 };
            totals = allocationLogData
                .filter(allocation => {
                    // Log each allocation being considered for better debugging
                    return allocation.beerbrandid === beerIdInt && allocation.distributorid === distributorIdInt;
                })
                .reduce((totals, allocation) => {
                    totals.cases += allocation.casesallocated || 0;
                    totals.halfBarrels += allocation.halfbarrelsallocated || 0;
                    totals.quarterBarrels += allocation.quarterbarrelsallocated || 0;
                    totals.sixthBarrels += allocation.sixthbarrelsallocated || 0;
                    return totals;
                }, { cases: 0, halfBarrels: 0, quarterBarrels: 0, sixthBarrels: 0 });
        }

        return totals;
    };

    const calculateRemainingAllocations = (beerId) => {
        const allocationEntry = aggregatedAllocations.find(a => a.beerbrandid === beerId) || {
            totalcases: 0, totalhalfbarrels: 0, totalquarterbarrels: 0, totalsixthbarrels: 0
        };
        const productionEntry = aggregatedProduction.find(p => p.beerbrandid === beerId) || {
            totalcases: 0, totalhalfbarrels: 0, totalquarterbarrels: 0, totalsixthbarrels: 0
        };

        // Assuming these properties represent the remaining quantities
        return {
            cases: productionEntry.totalcases - (allocationEntry.totalcases),
            halfBarrels: productionEntry.totalhalfbarrels - (allocationEntry.totalhalfbarrels),
            quarterBarrels: productionEntry.totalquarterbarrels - (allocationEntry.totalquarterbarrels),
            sixthBarrels: productionEntry.totalsixthbarrels - (allocationEntry.totalsixthbarrels)
        };
    };

    const sortBeerBrandsByRemainingCases = (beerBrands) => {
        // Sort beer brands only if reconciliation mode is OFF
        if (!isReconciliationMode) {
            // Step 1: Filter out "51K", "Mykiss", and "Grand Rabbits" for manual ordering
            const manualOrderBrands = ["51K IPA", "Mykiss IPA", "Grand Rabbits Cream Ale"];
            const manuallyOrderedBrands = beerBrands.filter(beer =>
                manualOrderBrands.includes(beer.brandname)
            );

            // Step 2: Sort the remaining brands by remaining cases
            const otherBrands = beerBrands.filter(beer =>
                !manualOrderBrands.includes(beer.brandname)
            ).sort((a, b) => {
                const remainingA = calculateRemainingAllocations(a.beerbrandid).cases;
                const remainingB = calculateRemainingAllocations(b.beerbrandid).cases;
                return remainingB - remainingA; // Sort in descending order by cases
            });

            // Step 3: Return the manually ordered brands followed by the sorted rest
            return [
                ...manuallyOrderedBrands,
                ...otherBrands
            ];
        }
        // Return unsorted if reconciliation mode is ON
        return beerBrands;
    };

    const toggleReconciliationMode = () => {
        console.log("TOGGLED");
        setIsReconciliationMode(prevMode => !prevMode);
    };

    const initializeEditableInventory = () => {
        const inventory = {};
        beerBrands.forEach(beer => {
            const remainingTotals = calculateRemainingAllocations(beer.beerbrandid);
            inventory[beer.beerbrandid] = {
                cases: remainingTotals.cases,
                halfBarrels: remainingTotals.halfBarrels,
                quarterBarrels: remainingTotals.quarterBarrels,
                sixthBarrels: remainingTotals.sixthBarrels
            };
        });
        setEditableInventory(inventory);
    };

    const initializeEditablePub = () => {
        const inventory = {};
        beerBrands.forEach(beer => {
            const remainingTotals = calculateAllocationTotals(beer.beerbrandid, PUB_ID, pubInventory);
            inventory[beer.beerbrandid] = {
                pubCases: remainingTotals.pubCases,
                pubHalfBarrels: remainingTotals.pubHalfBarrels,
                pubQuarterBarrels: remainingTotals.pubQuarterBarrels,
                pubSixthBarrels: remainingTotals.pubSixthBarrels
            };
        });
        setEditablePub(inventory);
    };

    const handleInventoryChange = async (beerId, field, newValue) => {
        const oldValue = editableInventory[beerId][field];
        const difference = oldValue - newValue;

        if (difference !== 0) {
            setEditableInventory(prev => ({
                ...prev,
                [beerId]: {
                    ...prev[beerId],
                    [field]: newValue
                }
            }));

            try {
                const newAllocationLog = {
                    beerbrandid: beerId,
                    distributorid: INVENTORY_BALANCE_ID,
                    date: new Date().toISOString().split('T')[0],
                    casesallocated: field === 'cases' ? difference : 0,
                    halfbarrelsallocated: field === 'halfBarrels' ? difference : 0,
                    quarterbarrelsallocated: field === 'quarterBarrels' ? difference : 0,
                    sixthbarrelsallocated: field === 'sixthBarrels' ? difference : 0
                };

                const { data, error } = await supabase
                    .from('allocationlog')
                    .insert(newAllocationLog);

                if (error) throw error;

                refreshAllocationLogData();
                refreshAggregatedProduction();
                refreshAggregatedAllocations();
                refreshData();
            } catch (error) {
                console.error('Error submitting batch:', error.message);
            }
        }
    };

    const handlePubChange = async (beerId, field, newValue) => {
        const oldValue = editablePub[beerId][field];
        const difference = newValue - oldValue;
        const negDifference = oldValue - newValue;

        if (difference !== 0) {
            setEditablePub(prev => ({
                ...prev,
                [beerId]: {
                    ...prev[beerId],
                    [field]: newValue
                }
            }));

            try {
                const newDate = new Date().toISOString().split('T')[0];

                // Creating two logs: one for PUB_ID and one for OTHER_ID
                const newAllocationLog = [
                    {
                        beerbrandid: beerId,
                        distributorid: PUB_ID,
                        date: newDate,
                        casesallocated: field === 'pubCases' ? difference : 0,
                        halfbarrelsallocated: field === 'pubHalfBarrels' ? difference : 0,
                        quarterbarrelsallocated: field === 'pubQuarterBarrels' ? difference : 0,
                        sixthbarrelsallocated: field === 'pubSixthBarrels' ? difference : 0,
                        allocationnotes: "INVENTORY ADJUSTMENT"
                    },
                    {
                        beerbrandid: beerId,
                        distributorid: INVENTORY_BALANCE_ID,
                        date: newDate,
                        casesallocated: field === 'pubCases' ? negDifference : 0,
                        halfbarrelsallocated: field === 'pubHalfBarrels' ? negDifference : 0,
                        quarterbarrelsallocated: field === 'pubQuarterBarrels' ? negDifference : 0,
                        sixthbarrelsallocated: field === 'pubSixthBarrels' ? negDifference : 0,
                        allocationnotes: "INVENTORY ADJUSTMENT"
                    }
                ];

                const { data, error } = await supabase
                    .from('allocationlog')
                    .insert(newAllocationLog);

                if (error) throw error;

                refreshAllocationLogData();
                refreshAggregatedProduction();
                refreshAggregatedAllocations();
                refreshPubInventory();
                refreshData();
            } catch (error) {
                console.error('Error submitting batch:', error.message);
            }
        }
    };

    const submitChanges = async () => {
        for (const beerId in pendingChanges) {
            const changes = pendingChanges[beerId];

            // Update for inventory changes
            if (changes.cases !== undefined) {
                await handleInventoryChange(beerId, 'cases', changes.cases || 0);
            }
            if (changes.halfBarrels !== undefined) {
                await handleInventoryChange(beerId, 'halfBarrels', changes.halfBarrels || 0);
            }
            if (changes.quarterBarrels !== undefined) {
                await handleInventoryChange(beerId, 'quarterBarrels', changes.quarterBarrels || 0);
            }
            if (changes.sixthBarrels !== undefined) {
                await handleInventoryChange(beerId, 'sixthBarrels', changes.sixthBarrels || 0);
            }

            // Update for pub changes
            if (changes.pubCases !== undefined) {
                await handlePubChange(beerId, 'pubCases', changes.pubCases || 0);
            }
            if (changes.pubHalfBarrels !== undefined) {
                await handlePubChange(beerId, 'pubHalfBarrels', changes.pubHalfBarrels || 0);
            }
            if (changes.pubQuarterBarrels !== undefined) {
                await handlePubChange(beerId, 'pubQuarterBarrels', changes.pubQuarterBarrels || 0);
            }
            if (changes.pubSixthBarrels !== undefined) {
                await handlePubChange(beerId, 'pubSixthBarrels', changes.pubSixthBarrels || 0);
            }
        }

        // Refresh data after submitting changes
        refreshAllocationLogData();
        refreshAggregatedProduction();
        refreshAggregatedAllocations();
        refreshData();

        // Clear pending changes
        setPendingChanges({});
        toggleReconciliationMode();
    };

    const renderRowForBeer = (beer, rowIndex) => {
        const remainingTotals = isReconciliationMode && editableInventory[beer.beerbrandid]
            ? editableInventory[beer.beerbrandid]
            : calculateRemainingAllocations(beer.beerbrandid);
        const pubTotals = isReconciliationMode && editablePub[beer.beerbrandid]
            ? editablePub[beer.beerbrandid]
            : calculateAllocationTotals(beer.beerbrandid, PUB_ID, pubInventory);
        const beerId = beer.beerbrandid;


        const renderInventoryCell = (field) => {
            const value = remainingTotals[field];
            const className = value === 0 ? 'grey-text' : value < 0 ? 'red-text' : '';

            if (isReconciliationMode && editableInventory[beer.beerbrandid]) {
                return (
                    <input
                        className='shorter-input'
                        type="number"
                        defaultValue={value}
                        onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            setPendingChanges(prev => ({
                                ...prev,
                                [beerId]: {
                                    ...prev[beerId],
                                    [field]: newValue
                                }
                            }));
                        }}
                    />
                );
            }
            return <span className={className}>{value}</span>;
        };

        const renderPubCell = (field) => {
            const value = pubTotals[field];
            const className = value === 0 ? 'grey-text' : '';
            if (isReconciliationMode && editablePub[beer.beerbrandid]) {
                return (
                    <input
                        className='shorter-input'
                        type="number"
                        defaultValue={value}
                        onChange={(e) => {
                            const newValue = parseInt(e.target.value, 10);
                            setPendingChanges(prev => ({
                                ...prev,
                                [beerId]: {
                                    ...prev[beerId],
                                    [field]: newValue
                                }
                            }));
                        }}
                    />
                );
            }
            return <span className={className}>{value}</span>;
        };

        return (
            <tr key={rowIndex}>
                <td>{beer.brandname}</td>
                <td>{renderInventoryCell('cases')}</td>
                <td>{renderInventoryCell('halfBarrels')}</td>
                <td>{renderInventoryCell('quarterBarrels')}</td>
                <td>{renderInventoryCell('sixthBarrels')}</td>
                <td className='distributors-spacing'>{renderPubCell('pubCases')}</td>
                <td>{renderPubCell('pubHalfBarrels')}</td>
                {isReconciliationMode && (
                    <td>{renderPubCell('pubQuarterBarrels')}</td>
                )}
                {isReconciliationMode && (
                    <td>{renderPubCell('pubSixthBarrels')}</td>
                )}
            </tr>
        );
    };

    return (
        <div>
            <button className="reconciliation-mode" onClick={toggleReconciliationMode}>
                {isReconciliationMode ? "Exit Reconciliation Mode" : "Enter Reconciliation Mode"}
            </button>

            {isReconciliationMode && (
                <button onClick={submitChanges}>Submit Changes</button>
            )}

            <table>
                {!isReconciliationMode && (<thead>
                    <tr>
                        <th>Beer</th>
                        <th colSpan={4}>Production Inventory</th>
                        <th className='distributors-spacing' colSpan={2}>Pub Inventory</th>
                    </tr>
                    <tr>
                        <th>Beer</th>
                        <th>Cases</th>
                        <th>1/2s</th>
                        <th>1/4s</th>
                        <th>1/6s</th>
                        <th className='distributors-spacing'>Cases</th>
                        <th>1/2s</th>
                    </tr>
                </thead>)}
                {isReconciliationMode && (<thead>
                    <tr>
                        <th>Beer</th>
                        <th colSpan={4}>Production Inventory</th>
                        <th className='distributors-spacing' colSpan={4}>Pub Inventory</th>
                    </tr>
                    <tr>
                        <th>Beer</th>
                        <th>Cases</th>
                        <th>1/2s</th>
                        <th>1/4s</th>
                        <th>1/6s</th>
                        <th className='distributors-spacing'>Cases</th>
                        <th>1/2s</th>
                        <th>1/4s</th>
                        <th>1/6s</th>
                    </tr>
                </thead>)}
                <tbody>
                    {!isReconciliationMode && sortBeerBrandsByRemainingCases(selectedBeerBrands).map((beer, index) => renderRowForBeer(beer, index))}
                    {isReconciliationMode && beerBrands.map((beer, index) => renderRowForBeer(beer, index))}
                </tbody>
            </table>
        </div>
    );
};

export default Inventory;
