import React, { useState, useContext, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Button, MenuItem, Select, Tabs, Tab } from '@mui/material';
import { supabase } from '../../supabaseClient';
import { DataContext } from '../../contexts/DataContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function BrandChartWidget() {
    const { beerBrands, distributors } = useContext(DataContext);
    const [brandPerformance, setBrandPerformance] = useState([]);
    const [totals, setTotals] = useState({
        totalCasesAllocated: 0,
        totalHalfBarrelsAllocated: 0,
        totalQuarterBarrelsAllocated: 0,
        totalSixthBarrelsAllocated: 0,
    });
    const [selectedDistributor, setSelectedDistributor] = useState('');
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
    const [tabIndex, setTabIndex] = useState(0);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        fetchBrandPerformance();
    }, [selectedDistributor, dateRange]);

    const fetchBrandPerformance = async () => {
        if (!selectedDistributor || !dateRange.startDate || !dateRange.endDate) return;

        const { data, error } = await supabase
            .from('allocationlog')
            .select(`
                beerbrandid,
                casesallocated,
                halfbarrelsallocated,
                quarterbarrelsallocated,
                sixthbarrelsallocated,
                distributorid,
                date
            `)
            .eq('distributorid', selectedDistributor)
            .gte('date', dateRange.startDate)
            .lte('date', dateRange.endDate);

        if (error) {
            console.error('Error fetching brand performance:', error);
            return;
        }

        // Aggregate the allocations by date and beerbrand
        const performanceMap = {};
        data.forEach((log) => {
            const date = log.date.split('T')[0]; // format date as YYYY-MM-DD
            if (!performanceMap[date]) {
                performanceMap[date] = {};
            }

            if (!performanceMap[date][log.beerbrandid]) {
                performanceMap[date][log.beerbrandid] = {
                    casesAllocated: 0,
                    halfBarrelsAllocated: 0,
                    quarterBarrelsAllocated: 0,
                    sixthBarrelsAllocated: 0,
                };
            }

            performanceMap[date][log.beerbrandid].casesAllocated += log.casesallocated;
            performanceMap[date][log.beerbrandid].halfBarrelsAllocated += log.halfbarrelsallocated;
            performanceMap[date][log.beerbrandid].quarterBarrelsAllocated += log.quarterbarrelsallocated;
            performanceMap[date][log.beerbrandid].sixthBarrelsAllocated += log.sixthbarrelsallocated;
        });

        // Prepare running total chart data
        const runningTotals = {};
        let chartData = [];

        const dates = Object.keys(performanceMap).sort(); // Sort dates in ascending order
        dates.forEach((date) => {
            const dateData = { date };

            beerBrands.forEach((brand) => {
                const allocations = performanceMap[date][brand.beerbrandid] || {};
                runningTotals[brand.brandname] = runningTotals[brand.brandname] || {
                    casesAllocated: 0,
                    halfBarrelsAllocated: 0,
                    quarterBarrelsAllocated: 0,
                    sixthBarrelsAllocated: 0,
                };

                // Update running totals
                runningTotals[brand.brandname].casesAllocated += allocations.casesAllocated || 0;
                runningTotals[brand.brandname].halfBarrelsAllocated += allocations.halfBarrelsAllocated || 0;
                runningTotals[brand.brandname].quarterBarrelsAllocated += allocations.quarterBarrelsAllocated || 0;
                runningTotals[brand.brandname].sixthBarrelsAllocated += allocations.sixthBarrelsAllocated || 0;

                // Depending on the selected tab (Cases, 1/2 Barrels, etc.), populate chartData
                if (tabIndex === 0) {
                    dateData[brand.brandname] = runningTotals[brand.brandname].casesAllocated;
                } else if (tabIndex === 1) {
                    dateData[brand.brandname] = runningTotals[brand.brandname].halfBarrelsAllocated;
                } else if (tabIndex === 2) {
                    dateData[brand.brandname] = runningTotals[brand.brandname].quarterBarrelsAllocated;
                } else if (tabIndex === 3) {
                    dateData[brand.brandname] = runningTotals[brand.brandname].sixthBarrelsAllocated;
                }
            });

            chartData.push(dateData);
        });

        setChartData(chartData);
    };

    // Helper function to get today's date in YYYY-MM-DD format
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    // Helper function to get the start of the current year in YYYY-MM-DD format
    const getStartOfYear = () => {
        const yearStart = new Date(new Date().getFullYear(), 0, 1);
        return yearStart.toISOString().split('T')[0];
    };

    // Helper function to get the start of the current month in YYYY-MM-DD format
    const getStartOfMonth = () => {
        const monthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        return monthStart.toISOString().split('T')[0];
    };

    // Add missing functions to handle date range buttons
    const handleYTD = () => {
        const startDate = getStartOfYear();
        const endDate = getTodayDate();
        setDateRange({ startDate, endDate });
    };

    const handleMonthToDate = () => {
        const startDate = getStartOfMonth();
        const endDate = getTodayDate();
        setDateRange({ startDate, endDate });
    };

    const handleAllTime = () => {
        const startDate = '2021-01-01'; // Assuming all-time starts from 2021
        const endDate = getTodayDate();
        setDateRange({ startDate, endDate });
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        fetchBrandPerformance(); // Re-fetch performance data when tab changes
    };

    const handleBrandSelectionChange = (event) => {
        setSelectedBrands(event.target.value);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Brand Allocation by Distributor</Typography>

                {/* Distributor Dropdown */}
                <Select
                    value={selectedDistributor}
                    onChange={(e) => setSelectedDistributor(e.target.value)}
                    displayEmpty
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '16px' }}
                >
                    <MenuItem value="" disabled>Select Distributor</MenuItem>
                    {distributors.map((distributor) => (
                        <MenuItem key={distributor.distributorid} value={distributor.distributorid}>
                            {distributor.distributorname}
                        </MenuItem>
                    ))}
                </Select>

                {/* Time Range and Date Picker */}
                <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                    <Button variant="outlined" onClick={handleYTD} style={{ marginRight: '8px' }}>YTD</Button>
                    <Button variant="outlined" onClick={handleMonthToDate} style={{ marginRight: '8px' }}>Month to Date</Button>
                    <Button variant="outlined" onClick={handleAllTime}>All Time</Button>

                    <TextField
                        label="Start Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.startDate}
                        onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                        style={{ marginRight: '16px' }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.endDate}
                        onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                    />
                </div>

                {/* Brand Selection */}
                <Select
                    multiple
                    value={selectedBrands}
                    onChange={handleBrandSelectionChange}
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '16px' }}
                >
                    {beerBrands.map((brand) => (
                        <MenuItem key={brand.beerbrandid} value={brand.brandname}>
                            {brand.brandname}
                        </MenuItem>
                    ))}
                </Select>

                {/* Tabs for different allocation sizes */}
                <Tabs value={tabIndex} onChange={handleTabChange} style={{ marginBottom: '16px' }}>
                    <Tab label="Cases" />
                    <Tab label="1/2 Barrels" />
                    <Tab label="1/4 Barrels" />
                    <Tab label="1/6 Barrels" />
                </Tabs>

                {/* Graph Display */}
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {selectedBrands.map((brand) => (
                            <Line key={brand} type="monotone" dataKey={brand} stroke="#8884d8" activeDot={{ r: 8 }} />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}

export default BrandChartWidget;
