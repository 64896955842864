import React from 'react';
import { Grid } from '@mui/material';
import TankUtilizationWidget from './widgets/TankUtilizationWidget';
import BrandPerformanceWidget from './widgets/BrandPerformanceWidget';
import RecentNotesWidget from './widgets/RecentNotesWidget';
import ProductionWidget from './widgets/ProductionWidget';
import AllocationWidget from './widgets/AllocationWidget';
import NextPackagingWidget from './widgets/NextPackagingWidget';
import BrandChartWidget from './widgets/BrandChartWidget';

function Reports() {
    return (
        <div className="reports-page">
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <NextPackagingWidget />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <BrandPerformanceWidget />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <BrandChartWidget />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <ProductionWidget />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TankUtilizationWidget />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <RecentNotesWidget />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <AllocationWidget />
                </Grid>
            </Grid>
        </div>
    );
}

export default Reports;
