import React, { useState, useContext, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';

function EventsAllocation() {
    const { refreshData, beerBrands, distributors } = useContext(DataContext);
    const { pubInventory, refreshPubInventory } = useContext(DataContext);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedFor, setSelectedFor] = useState('');
    const [cases, setCases] = useState(0);
    const [halfBarrels, setHalfBarrels] = useState(0);
    const [quarterBarrels, setQuarterBarrels] = useState(0);
    const [sixthBarrels, setSixthBarrels] = useState(0);
    const [batch, setBatch] = useState([]);
    const [viewMode, setViewMode] = useState('allocation'); // 'allocation' or 'history'
    const [allocationHistory, setAllocationHistory] = useState([]);
    const [EventsDistributorId, setEventsDistributorId] = useState(null);
    const [pubDistributorId, setPubDistributorId] = useState(null);
    const [currentPubInventory, setCurrentPubInventory] = useState({ cases: 0, halfBarrels: 0, quarterBarrels: 0, sixthBarrels: 0 });

    const [showSummary, setShowSummary] = useState(false);
    const [notes, setNotes] = useState('');

    useEffect(() => {
        const EventsDistributor = distributors.find(d => d.distributorname === "Events");
        const pubDistributor = distributors.find(d => d.distributorname === "Pub");
        if (EventsDistributor) setEventsDistributorId(EventsDistributor.distributorid);
        if (pubDistributor) setPubDistributorId(pubDistributor.distributorid);

        if (viewMode === 'history') {
            fetchAllocationHistory();
        }
    }, [viewMode, distributors]);

    useEffect(() => {
        if (selectedBrand) {
            refreshPubInventory();
            calculatePubInventory(selectedBrand, pubInventory);
        }
    }, [selectedBrand]);

    const fetchAllocationHistory = async () => {
        if (!EventsDistributorId) return;

        try {
            const { data, error } = await supabase
                .from('allocationlog')
                .select('*')
                .eq('distributorid', EventsDistributorId)
                .order('date', { ascending: false });

            if (error) throw error;
            setAllocationHistory(data);
        } catch (error) {
            console.error('Error fetching allocation history:', error.message);
        }
    };

    const handleAddToBatch = () => {
        const newAllocation = {
            beerbrandid: parseInt(selectedBrand),
            casesallocated: parseInt(cases),
            halfbarrelsallocated: parseInt(halfBarrels),
            quarterbarrelsallocated: parseInt(quarterBarrels),
            sixthbarrelsallocated: parseInt(sixthBarrels),
        };

        setBatch([...batch, newAllocation]);

        setCases(0);
        setHalfBarrels(0);
        setQuarterBarrels(0);
        setSixthBarrels(0);
        setSelectedBrand('');
    };

    const calculatePubInventory = async (brandId, pub) => {
        try {
            const pubTotal = pub.find(u => u.beerbrandid === parseInt(brandId, 10));

            setCurrentPubInventory({
                cases: pubTotal.totalcases,
                halfBarrels: pubTotal.totalhalfbarrels,
                quarterBarrels: pubTotal.totalquarterbarrels,
                sixthBarrels: pubTotal.totalsixthbarrels
            });
        } catch (error) {
            console.error('Error calculating Pub inventory:', error.message);
        }
    };

    const handleDeleteItem = (index) => {
        const newBatch = batch.filter((_, i) => i !== index);
        setBatch(newBatch);
    };

    const handleSubmitBatch = async () => {
        if (!EventsDistributorId) {
            alert("Error: 'Events' distributor not found");
            return;
        }

        setShowSummary(true);
    };

    const handleConfirmAllocation = async () => {
        try {
            const allocationsToInsert = batch.map(item => ({
                date: new Date().toISOString().split('T')[0], // Current date
                distributorid: EventsDistributorId,
                ...item,
                allocationnotes: selectedFor + ': ' + notes
            }));

            const { data, error } = await supabase
                .from('allocationlog')
                .insert(allocationsToInsert);

            if (error) throw error;

            setBatch([]);
            setShowSummary(false);
            setNotes('');
            refreshData();
        } catch (error) {
            console.error('Error submitting batch:', error.message);
        }
    };

    const handleCancelAllocation = async () => {
        setShowSummary(false);
    };

    const toggleViewMode = () => {
        setViewMode(viewMode === 'allocation' ? 'history' : 'allocation');
    };

    const calculateTotals = () => {
        return batch.reduce((totals, item) => ({
            cases: totals.cases + item.casesallocated,
            halfBarrels: totals.halfBarrels + item.halfbarrelsallocated,
            quarterBarrels: totals.quarterBarrels + item.quarterbarrelsallocated,
            sixthBarrels: totals.sixthBarrels + item.sixthbarrelsallocated
        }), { cases: 0, halfBarrels: 0, quarterBarrels: 0, sixthBarrels: 0 });
    };

    return (
        <div>
            <div className='events-header'>
                <button onClick={toggleViewMode}>
                    {viewMode === 'allocation' ? 'View History' : 'New Allocation'}
                </button>
            </div>
            <div className='events-window'>
                {viewMode === 'allocation' ? (
                    <>
                        {!showSummary ? (
                            <>
                                <div className='events-input'>
                                    <div className='brand-dropdown'>
                                        <label htmlFor="brand">Brand</label>
                                        <select
                                            id="brand"
                                            value={selectedBrand}
                                            onChange={(e) => setSelectedBrand(e.target.value)}
                                        >
                                            <option value="">Select Brand</option>
                                            {beerBrands.map((brand) => (
                                                <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                                    {brand.brandname}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='for-dropdown'>
                                        <label htmlFor="for">For</label>
                                        <select
                                            id="for"
                                            value={selectedFor}
                                            onChange={(e) => setSelectedFor(e.target.value)}
                                        >
                                            <option value="">Select Option</option>
                                            <option value="Employee">Employee</option>
                                            <option value="Investor">Investor</option>
                                            <option value="Events">Events</option>
                                        </select>
                                    </div>

                                    <div className='events-cases'>
                                        <label htmlFor="cases">Cases</label>
                                        <input
                                            id="cases"
                                            type="number"
                                            value={cases === 0 ? '' : cases} // Empty string if 0
                                            onChange={(e) => setCases(e.target.value)}
                                        />
                                    </div>

                                    <div className='events-halves'>
                                        <label htmlFor="halfBarrels">1/2 Barrels</label>
                                        <input
                                            id="halfBarrels"
                                            type="number"
                                            value={halfBarrels === 0 ? '' : halfBarrels}
                                            onChange={(e) => setHalfBarrels(e.target.value)}
                                        />
                                    </div>
                                    <div className='events-quarters'>
                                        <label htmlFor="quarterBarrels">1/4 Barrels</label>
                                        <input
                                            id="quarterBarrels"
                                            type="number"
                                            value={quarterBarrels === 0 ? '' : quarterBarrels}
                                            onChange={(e) => setQuarterBarrels(e.target.value)}
                                        />
                                    </div>
                                    <div className='events-sixths'>
                                        <label htmlFor="sixthBarrels">1/6 Barrels</label>
                                        <input
                                            id="sixthBarrels"
                                            type="number"
                                            value={sixthBarrels === 0 ? '' : sixthBarrels}
                                            onChange={(e) => setSixthBarrels(e.target.value)}
                                        />
                                    </div>
                                    <div className='pub-inventory'>
                                        <p>Inventory Cases: {currentPubInventory.cases}</p>
                                        <p>Inventory 1/2 Barrels: {currentPubInventory.halfBarrels}</p>
                                        <p>Inventory 1/4 Barrels: {currentPubInventory.quarterBarrels}</p>
                                        <p>Inventory 1/6 Barrels: {currentPubInventory.sixthBarrels}</p>
                                    </div>
                                </div>

                                <div className='events-button'>
                                    <button onClick={handleAddToBatch}>Add {'>'}</button>
                                </div>

                                <div className='events-batchview'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Brand</th>
                                                <th>Cases</th>
                                                <th>Half Barrels</th>
                                                <th>Quarter Barrels</th>
                                                <th>Sixth Barrels</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {batch.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <button onClick={() => handleDeleteItem(index)}>X</button>
                                                    </td>
                                                    <td>{beerBrands.find(brand => brand.beerbrandid === item.beerbrandid)?.brandname || 'Unknown'}</td>
                                                    <td>{item.casesallocated}</td>
                                                    <td>{item.halfbarrelsallocated}</td>
                                                    <td>{item.quarterbarrelsallocated}</td>
                                                    <td>{item.sixthbarrelsallocated}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className='events-button'>
                                    <button onClick={handleSubmitBatch}>Submit</button>
                                </div>
                            </>
                        ) : (
                            <div className='events-summary-window'>
                                {/* Summary and Notes Section */}
                                <h3>Allocation Summary</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Brand</th>
                                            <th>Cases</th>
                                            <th>Half Barrels</th>
                                            <th>Quarter Barrels</th>
                                            <th>Sixth Barrels</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {batch.map((item, index) => (
                                            <tr key={index}>
                                                <td>{beerBrands.find(brand => brand.beerbrandid === item.beerbrandid)?.brandname || 'Unknown'}</td>
                                                <td>{item.casesallocated}</td>
                                                <td>{item.halfbarrelsallocated}</td>
                                                <td>{item.quarterbarrelsallocated}</td>
                                                <td>{item.sixthbarrelsallocated}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td><strong>Total</strong></td>
                                            <td>{calculateTotals().cases}</td>
                                            <td>{calculateTotals().halfBarrels}</td>
                                            <td>{calculateTotals().quarterBarrels}</td>
                                            <td>{calculateTotals().sixthBarrels}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* Notes Input */}
                                <div>
                                    <label htmlFor="notes">Who, What, Why:</label>
                                    <textarea
                                        id="notes"
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                </div>
                                <div className='events-button'>
                                    <button onClick={handleConfirmAllocation}>Confirm Allocation</button>
                                    <button onClick={handleCancelAllocation}>Cancel/Go Back</button>
                                </div>
                            </div>
                        )}

                    </>
                ) : (
                    <div className='events-history'>
                        <h3>Events Allocation History</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Brand</th>
                                    <th>Cases</th>
                                    <th>Half Barrels</th>
                                    <th>Quarter Barrels</th>
                                    <th>Sixth Barrels</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allocationHistory.map((allocation, index) => (
                                    <tr key={index}>
                                        <td>{allocation.date}</td>
                                        <td>{beerBrands.find(brand => brand.beerbrandid === allocation.beerbrandid)?.brandname}</td>
                                        <td>{allocation.casesallocated}</td>
                                        <td>{allocation.halfbarrelsallocated}</td>
                                        <td>{allocation.quarterbarrelsallocated}</td>
                                        <td>{allocation.sixthbarrelsallocated}</td>
                                        <td>{allocation.allocationnotes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>

    );
}

export default EventsAllocation;