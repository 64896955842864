import React, { useContext, useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { WithContext as ReactTags } from 'react-tag-input';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';
import Papa from 'papaparse';

const KeyCodes = {
    comma: 188,
    enter: 13,
    space: 32,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

function InputRun() {
    const { refreshData } = useContext(DataContext);
    const { beerBrands } = useContext(DataContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedBeerBrand, setSelectedBeerBrand] = useState('');
    const [error, setError] = useState('');
    const [inputting, setInputting] = useState(false);
    const [batchIDs, setBatchIDs] = useState([]);
    const [runID, setRunID] = useState('');
    const [batchSize, setBatchSize] = useState('');
    const [botc, setBOTC] = useState('');
    const [totalCases, setTotalCases] = useState('');
    const [totalHalfBarrels, setTotalHalfBarrels] = useState('');
    const [totalQuarterBarrels, setTotalQuarterBarrels] = useState('');
    const [totalSixthBarrels, setTotalSixthBarrels] = useState('');
    const [notes, setNotes] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [currentCsvIndex, setCurrentCsvIndex] = useState(0);
    const [csvYear, setCsvYear] = useState(new Date().getFullYear());
    const [showYearInput, setShowYearInput] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const [availableBatches, setAvailableBatches] = useState([]);
    const fileInputRef = useRef(null);
    const [selectedBeerBrandCounts, setSelectedBeerBrandCounts] = useState({});

    const handleBrandClick = (beerbrandid) => {
        setSelectedBeerBrandCounts(prev => ({
            ...prev,
            [beerbrandid]: (prev[beerbrandid] || 0) + 1
        }));
    };


    useEffect(() => {
        fetchAvailableBatches();
        console.log(availableBatches);
    }, []);

    const fetchAvailableBatches = async () => {
        const { data, error } = await supabase
            .from('batch')
            .select(`
                batchid,
                beerbrandid,
                fermentationvesselid,
                fermentationvessels!batch_fermentationvesselid_fkey (capacity),
                status
            `)
            .in('status', ['Ready To Can', null]);

        console.log("Fetched data:", data); // Log fetched data for debugging
        console.log("Fetch error:", error); // Log any error

        if (error) {
            console.error('Error fetching available batches', error);
            setError('Failed to load available batches.');
        } else if (data) {
            setAvailableBatches(data);
        } else {
            console.warn("No data received from Supabase.");
        }
    };


    const calculateRunEfficiency = (cases, halves, quarters, sixths, batchSize) => {
        cases = parseInt(cases) || 0;
        halves = parseInt(halves) || 0;
        quarters = parseInt(quarters) || 0;
        sixths = parseInt(sixths) || 0;
        batchSize = parseFloat(batchSize) || 0;
        return ((cases * 288 + halves * 1984 + quarters * 992 + sixths * 661.3) / (batchSize * 3968)) || 0;
    };

    const toggleInputting = () => setInputting(!inputting);

    const handleAddition = (tag) => {
        if (!batchIDs.some(existingTag => existingTag.id === tag.id)) {
            setBatchIDs([...batchIDs, tag]);
        }
    };

    const handleDelete = (i) => {
        setBatchIDs(batchIDs.filter((tag, index) => index !== i));
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            complete: (result) => {
                console.log("Parsed result:", result); // For debugging
                if (result.data && Array.isArray(result.data)) {
                    const parsedData = result.data.filter(row => {
                        // Check if row is an object and has at least one non-empty value
                        return typeof row === 'object' && Object.values(row).some(value => value !== "");
                    });
                    if (parsedData.length > 0) {
                        setCsvData(parsedData);
                        setCurrentCsvIndex(0);
                        setShowYearInput(true);
                    } else {
                        setError("No valid data found in the CSV file.");
                    }
                } else {
                    setError("Failed to parse CSV file. Please check the file format.");
                }
            },
            header: true,
            error: (error) => {
                console.error("CSV parsing error:", error);
                setError("Error parsing CSV file: " + error.message);
            }
        });
    };

    const handleYearSubmit = () => {
        setShowYearInput(false);
        setIsImporting(true);
        loadCsvRow(csvData[0]);
    };

    const loadCsvRow = (row) => {
        if (typeof row !== 'object') {
            console.error("Invalid row data:", row);
            setError("Error loading CSV row: Invalid data format");
            return;
        }

        setSelectedDate(parseDate(row.date, csvYear));
        setRunID(row.runid || '');

        // Process the batch IDs
        let batchIDArray = row.batchids ? row.batchids.split(' ') : [];
        let processedBatchIDs = [];
        let lastFourDigitId = '';

        batchIDArray.forEach(id => {
            if (id.length === 4) {
                // Store the first 4-digit ID
                lastFourDigitId = id;
                processedBatchIDs.push({ id, text: id });
            } else if (id.length === 2 && lastFourDigitId) {
                // Expand 2-digit ID by prepending the first two digits of the last 4-digit ID
                const newId = lastFourDigitId.slice(0, 2) + id;
                processedBatchIDs.push({ id: newId, text: newId });
            } else {
                processedBatchIDs.push({ id, text: id }); // Add without changes if it's not 2 or 4 digits
            }
        });

        setBatchIDs(processedBatchIDs);

        // Find the beerbrandname in the CSV, match it to beerbrandid in beerBrands
        const matchingBrand = beerBrands.find(brand => brand.brandname === row.beerbrandid);
        const matchedBeerBrandId = matchingBrand ? matchingBrand.beerbrandid : ''; // Get the beerbrandid or empty if not found
        setSelectedBeerBrand(matchedBeerBrandId || ''); // Set the beerbrand ID

        setBatchSize(row.batchsize || '');
        setBOTC(row.botc || '');
        setTotalCases(row.totalcases || '');
        setTotalHalfBarrels(row.totalhalfbarrels || '');
        setTotalQuarterBarrels(row.totalquarterbarrels || '');
        setTotalSixthBarrels(row.totalsixthbarrels || '');
        setNotes(row.notes || '');
    };

    const parseDate = (dateString, year) => {
        if (!dateString) {
            console.error("Invalid date string:", dateString);
            return new Date();
        }
        const [month, day] = dateString.split('/');
        return new Date(year, parseInt(month) - 1, parseInt(day));
    };

    const handleNextCsvRow = () => {
        if (currentCsvIndex < csvData.length - 1) {
            setCurrentCsvIndex(currentCsvIndex + 1);
            loadCsvRow(csvData[currentCsvIndex + 1]);
        } else {
            finishImport();
        }
    };

    const handleRunRecordSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Reset error on new submission
        const runEfficiency = calculateRunEfficiency(totalCases, totalHalfBarrels, totalQuarterBarrels, totalSixthBarrels, batchSize);
        const parsedBatchIDs = batchIDs.map(tag => parseInt(tag.id)).filter(id => !isNaN(id));
        const { data, error } = await supabase.from('runslog').insert([
            {
                runid: parseInt(runID),
                date: selectedDate,
                batchids: parsedBatchIDs,
                beerbrandid: selectedBeerBrand,
                batchsize: parseFloat(batchSize),
                botc: botc || '',
                totalcases: parseInt(totalCases) || 0,
                totalhalfbarrels: parseInt(totalHalfBarrels) || 0,
                totalquarterbarrels: parseInt(totalQuarterBarrels) || 0,
                totalsixthbarrels: parseInt(totalSixthBarrels) || 0,
                runefficiency: runEfficiency,
                runnotes: notes || ''
            }
        ]);

        if (error) {
            console.error('Error adding run record', error);
            setError('An error occurred while submitting. Please try again. Error: ' + error.message + (error.details ? ' Details: ' + error.details : ''));
        } else {
            const { error: batchUpdateError } = await supabase
                .from('batch')
                .update({ status: 'Canned' })
                .in('batchid', parsedBatchIDs);

            if (batchUpdateError) {
                console.error('Error updating batch statuses', batchUpdateError);
                setError('An error occurred while updating batches. Please try again. Error: ' + batchUpdateError.message + (batchUpdateError.details ? ' Details: ' + batchUpdateError.details : ''));
            }

            if (isImporting && currentCsvIndex < csvData.length - 1) {
                handleNextCsvRow();
            } else {
                finishImport();
            }
        }

        refreshData();
    };

    const handleBatchSelect = (batch) => {
        // Convert batchid and beerbrandid to string to avoid trim() issues
        setBatchIDs([{ id: batch.batchid.toString(), text: batch.batchid.toString() }]);

        const matchingBrand = beerBrands.find(brand => brand.beerbrandid === batch.beerbrandid);
        setSelectedBeerBrand(matchingBrand ? matchingBrand.beerbrandid.toString() : '');

        // Set batch size from the `fermentationvessels.capacity`
        setBatchSize(batch.fermentationvessels?.capacity?.toString() || '');
    };


    const cancelImport = () => {
        setCsvData([]);
        setCurrentCsvIndex(0);
        setShowYearInput(false);
        setIsImporting(false);
        resetForm();
    };

    const finishImport = () => {
        setCsvData([]);
        setCurrentCsvIndex(0);
        setShowYearInput(false);
        setIsImporting(false);
        resetForm();
        toggleInputting();
    };

    const resetForm = () => {
        setSelectedDate(new Date());
        setRunID('');
        setBatchIDs([]);
        setSelectedBeerBrand('');
        setBatchSize('');
        setBOTC('');
        setTotalCases('');
        setTotalHalfBarrels('');
        setTotalQuarterBarrels('');
        setTotalSixthBarrels('');
        setNotes('');
    };

    return (
        <div className='info-row'>
            {error && <div className='Error' style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
            {inputting ? (
                <div className='New-input'>
                    <button type="button" className="close-button" onClick={toggleInputting}>X</button>
                    {!isImporting && (
                        <>
                            <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileUpload}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                            {/* <button onClick={() => fileInputRef.current.click()}>
                                Import CSV
                            </button> */}

                            {availableBatches.length > 0 ? (
                                <>
                                    {availableBatches.map((batch) => (
                                        <button
                                            key={batch.batchid}
                                            onClick={() => handleBatchSelect(batch)}
                                            className='batch-button'
                                        >
                                            {batch.batchid} <br /> {beerBrands.find(brand => brand.beerbrandid === batch.beerbrandid)?.brandname || 'Unknown Brand'} <br /> Size: {batch.fermentationvessels?.capacity || 'Unknown'}
                                        </button>
                                    ))}
                                </>
                            ) : (
                                <div>
                                </div>
                            )}
                        </>
                    )}

                    {showYearInput && (
                        <div className="year-input">
                            <input
                                type="number"
                                value={csvYear}
                                onChange={(e) => setCsvYear(parseInt(e.target.value))}
                                placeholder="Enter year for CSV data"
                            />
                            <button onClick={handleYearSubmit}>Set Year</button>
                            <button onClick={cancelImport}>Cancel Import</button>
                        </div>
                    )}
                    <form onSubmit={handleRunRecordSubmit}>
                        <div className='new-Input-Form'>
                            <div className='new-Input-Form-Values Input-run'>
                                <div>Date</div>
                                <div className='date'>
                                    <DatePicker selected={selectedDate} onChange={(date) => setSelectedDate(date)} />
                                </div>
                                <div>Run ID</div>
                                <div className='run-ID'>
                                    <input type="text" inputMode="numeric" pattern="\d*" value={runID} onChange={(e) => setRunID(e.target.value)} placeholder='1234' />
                                </div>
                                <div>Cases</div>
                                <div className='cases'>
                                    <input className='shorter-input' type="text" inputMode="numeric" pattern="-?\d*" value={totalCases} onChange={(e) => setTotalCases(e.target.value)} placeholder='1234' />
                                </div>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <div>Brand</div>
                                <div className='beer-Brand'>
                                    <select value={selectedBeerBrand} onChange={(e) => setSelectedBeerBrand(e.target.value)}>
                                        <option value="">Select a Brand</option>
                                        {beerBrands.map((brand) => (
                                            <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                                {brand.brandname}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>BOTC</div>
                                <div className='botc'>
                                    <input type="text" value={botc} onChange={(e) => setBOTC(e.target.value)} placeholder='...' />
                                </div>
                                <div>1/2s</div>
                                <div className='halves'>
                                    <input className='shorter-input' type="text" inputMode="numeric" pattern="-?\d*" value={totalHalfBarrels} onChange={(e) => setTotalHalfBarrels(e.target.value)} placeholder='12' />
                                </div>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <div>Batch IDs</div>
                                <div className='batch-Input'>
                                    <ReactTags
                                        tags={batchIDs}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        delimiters={delimiters}
                                        inputFieldPosition="inline"
                                        autocomplete
                                        inline
                                        classNames={{
                                            tags: 'ReactTags__tags',
                                            tagInput: 'ReactTags__tagInput',
                                            tagInputField: 'ReactTags__tagInputField',
                                            selected: 'ReactTags__selected',
                                            tag: 'ReactTags__tag',
                                            remove: 'ReactTags__remove',
                                        }}
                                    />
                                </div>
                                <div>Size</div>
                                <div className='batch-Size'>
                                    <input type="text" inputMode="numeric" pattern="\d*" value={batchSize} onChange={(e) => setBatchSize(e.target.value)} placeholder='120' />
                                </div>
                                <div>1/4s</div>
                                <div className='quarters'>
                                    <input className='shorter-input' type="text" inputMode="numeric" pattern="-?\d*" value={totalQuarterBarrels} onChange={(e) => setTotalQuarterBarrels(e.target.value)} placeholder='34' />
                                </div>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <div>Notes</div>
                                <div className='notes'>
                                    <input type="text" value={notes} onChange={(e) => setNotes(e.target.value)} placeholder='Add any notes here...' />
                                </div>
                                <span></span>
                                <span></span>
                                <div>1/6s</div>
                                <div className='sixths'>
                                    <input className='shorter-input' type="text" inputMode="numeric" pattern="-?\d*" value={totalSixthBarrels} onChange={(e) => setTotalSixthBarrels(e.target.value)} placeholder='56' />
                                </div>
                                <span></span>
                                <span></span>
                                <span></span>
                                <div className='add-Run-container'>
                                    <button className="add-Run" type="submit">
                                        {isImporting ? `Add (${currentCsvIndex + 1}/${csvData.length})` : 'Add'}
                                    </button>
                                    {isImporting && (
                                        <>
                                            <button type="button" onClick={handleNextCsvRow}>Skip</button>
                                            <button type="button" onClick={cancelImport}>Cancel</button>

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <div className='new-run-button' onClick={toggleInputting}>+ New Run</div>
            )}
        </div>
    );
}

export default InputRun;
