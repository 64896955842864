import { styles } from '../styles/brewStyles.js';

export const fetchBeerBrands = async (supabase) => {
    const { data, error } = await supabase.from('beerbrands').select('*');
    if (error) {
        console.error('Error fetching beer brands', error);
    } else {
        // Define the order for the first three brands
        const preferredOrder = ["51K IPA", "Mykiss IPA", "Grand Rabbits Cream Ale"];

        // Custom sort function
        const sortedData = data.sort((a, b) => {
            const aIndex = preferredOrder.indexOf(a.brandname);
            const bIndex = preferredOrder.indexOf(b.brandname);

            if (aIndex !== -1 && bIndex !== -1) {
                // If both are in the preferred order, sort by their predefined order
                return aIndex - bIndex;
            } else if (aIndex !== -1) {
                // If only 'a' is in the preferred order, it comes first
                return -1;
            } else if (bIndex !== -1) {
                // If only 'b' is in the preferred order, it comes first
                return 1;
            } else {
                // If neither are in the preferred order, sort alphabetically
                return a.brandname.localeCompare(b.brandname);
            }
        });

        // Set the sorted beer brands
        return (sortedData);
    }
};

export const fetchVessels = async (supabase) => {
    const { data, error } = await supabase.from('fermentationvessels').select('*');
    if (error) throw error;

    let newStyles = Array(15).fill(styles.openStyle);
    data.forEach(vessel => {
        newStyles[vessel.fermentationvesselid] = vessel.status === 'Full' ? styles.fullStyle : styles.openStyle;
    });

    return { vessels: data, styles: newStyles };
};

export const fetchBrites = async (supabase) => {
    const { data, error } = await supabase.from('britetanks').select('*');
    if (error) throw error;

    let newStyles = Array(5).fill(styles.openBrite);
    data.forEach(brite => {
        newStyles[brite.britetankids] = brite.status === 'Full' ? styles.fullBrite : styles.openBrite;
    });

    return { brites: data, styles: newStyles };
};
