// BrewCalendar.js
import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DataContext } from '../../contexts/DataContext';
import { Overlay, Popover, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { standardizeDateHandling } from '../../utils/dateUtils';

const localizer = momentLocalizer(moment);

const eventColors = {
    'Brew Date': '#3174ad',
    'Dry Hop Date': '#32cd32',
    'Crash Date': '#ff8c00',
    'Estimated Packaging Date': '#dc143c'
};

const BrewCalendar = ({ batches, vessels, brites, onDeleteBatch }) => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showPopover, setShowPopover] = useState(false);
    const [popoverPlacement, setPopoverPlacement] = useState('top');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [popoverTarget, setPopoverTarget] = useState(null);
    const { beerBrands } = useContext(DataContext);
    const calendarRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        if (!Array.isArray(batches) || !Array.isArray(beerBrands)) {
            console.error('One or more props are not arrays:', { batches, beerBrands });
            return;
        }

        const newEvents = [];

        batches.forEach(batch => {
            if (!batch) return;

            const beerBrand = beerBrands.find(brand => brand && brand.beerbrandid === batch.beerbrandid);
            const beerBrandName = beerBrand ? beerBrand.brandname : 'Unknown Brand';

            const addEvent = (date, title) => {
                if (date) {
                    newEvents.push({
                        id: `${batch.batchid}-${title.toLowerCase().replace(/\s/g, '-')}`,
                        title: `${beerBrandName} - ${title}`,
                        start: standardizeDateHandling.fromStorageFormat(date),
                        end: standardizeDateHandling.fromStorageFormat(date),
                        allDay: true,
                        batch: batch,
                        beerBrand: beerBrand || { brandname: 'Unknown Brand' },
                        eventType: title // Add eventType to the event
                    });
                }
            };

            // Add events for various dates related to the batch
            addEvent(batch.brewdate, 'Brew Date');
            addEvent(batch.dryhopdate, 'Dry Hop Date');
            addEvent(batch.crashdate, 'Crash Date');
            addEvent(batch.estimatedpackagingdate, 'Estimated Packaging Date');
        });

        setEvents(newEvents);
    }, [batches, beerBrands]);

    const eventStyleGetter = (event, start, end, isSelected) => {
        const backgroundColor = eventColors[event.eventType] || '#3174ad'; // Use the event type for color

        return {
            style: {
                backgroundColor,
                borderRadius: '0px',
                opacity: 0.8,
                color: 'white',
                border: '0px',
                display: 'block'
            }
        };
    };

    const handleSelectEvent = (event, e) => {
        setSelectedEvent(event);
        setPopoverTarget(e.currentTarget);

        const viewportHeight = window.innerHeight;
        const clickPosition = e.clientY;

        if (clickPosition < viewportHeight / 2) {
            setPopoverPlacement('bottom');
        } else {
            setPopoverPlacement('top');
        }

        setShowPopover(true);
    };


    const handleClosePopover = useCallback(() => {
        setShowPopover(false);
        setShowDeleteConfirmation(false);
        setSelectedEvent(null);
    }, []);

    const handleDeleteBatch = () => {
        setShowDeleteConfirmation(true);
    };

    const confirmDeleteBatch = () => {
        if (selectedEvent && selectedEvent.batch) {
            onDeleteBatch(selectedEvent.batch.batchid);
        }
        handleClosePopover();
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                handleClosePopover();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClosePopover]);

    const EventComponent = ({ event }) => (
        <OverlayTrigger
            placement={popoverPlacement}
            overlay={
                <Tooltip id={`tooltip-${event.id}`}>
                </Tooltip>
            }
        >
            <div>{event.title}</div>
        </OverlayTrigger>
    );

    const popoverStyle = {
        backgroundColor: '#101010',
        border: '1px solid #ccc',
        borderRadius: '4px',
        zIndex: '10000',
        boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
        padding: '10px',
        maxWidth: '300px',
    };

    return (
        <div style={{ height: '85vh', width: '100%' }} ref={calendarRef}>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                eventPropGetter={eventStyleGetter}
                views={['month', 'week', 'day']}
                defaultView='month'
                onSelectEvent={handleSelectEvent}
                components={{
                    event: EventComponent
                }}
            />

            <Overlay
                show={showPopover}
                target={popoverTarget}
                placement={popoverPlacement}
                container={calendarRef.current}
                containerPadding={20}
                ref={popoverRef}
            >
                <Popover id="popover-event-details" style={popoverStyle}>
                    <Popover.Header as="h3">{selectedEvent?.title}</Popover.Header>
                    <Popover.Body>
                        {selectedEvent && (
                            <div>
                                <p>Batch ID: {selectedEvent.batch.batchid}</p>
                                <p>Beer Brand: {selectedEvent.beerBrand.brandname}</p>
                                <p>Vessel: {selectedEvent.batch.fermentationvesselid ? `${selectedEvent.batch.fermentationvesselid ? 'FV' : 'Missing FV'}${selectedEvent.batch.fermentationvesselid || selectedEvent.batch.britetankids}` : 'Unknown'}</p>
                                <p>Brew Date: {moment(selectedEvent.batch.brewdate).format('MMMM D, YYYY')}</p>
                                <p>Dry Hop Date: {moment(selectedEvent.batch.dryhopdate).format('MMMM D, YYYY')}</p>
                                <p>Crash Date: {moment(selectedEvent.batch.crashdate).format('MMMM D, YYYY')}</p>
                                <p>Estimated Packaging Date: {moment(selectedEvent.batch.estimatedpackagingdate).format('MMMM D, YYYY')}</p>
                                <p>Original Gravity: {selectedEvent.batch.originalgravity}</p>
                                <p>Notes: {selectedEvent.batch.notes}</p>
                                {/* <Button variant="danger" size="sm" onClick={handleDeleteBatch}>
                                    Delete Batch
                                </Button> */}
                            </div>
                        )}
                    </Popover.Body>
                </Popover>
            </Overlay>

            <Overlay
                show={showDeleteConfirmation}
                target={popoverTarget}
                placement="top"
                container={calendarRef.current}
                containerPadding={20}
                ref={popoverRef}
            >
                <Popover id="popover-delete-confirmation" style={popoverStyle}>
                    <Popover.Header as="h3">Confirm Deletion</Popover.Header>
                    <Popover.Body>
                        <p>Are you sure you want to delete this batch? This action cannot be undone.</p>
                        <Button variant="secondary" size="sm" onClick={() => setShowDeleteConfirmation(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" size="sm" onClick={confirmDeleteBatch}>
                            Delete
                        </Button>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
};

export default BrewCalendar;
