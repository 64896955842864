// InputRequest.js
import React, { useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';


function InputRequest() {
    const { refreshData } = useContext(DataContext);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [urgency, setUrgency] = useState('Medium');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const { error } = await supabase.from('requests').insert([
            {
                date: selectedDate,
                urgency: urgency,
                title: title,
                description: description
            }
        ]);

        if (error) {
            console.error('Error adding request', error);
            setError('An error occurred. Please try again.');
        } else {
            // Reset form after successful submission
            setSelectedDate(new Date());
            setUrgency('Medium');
            setTitle('');
            setDescription('');
            // Optionally, refresh data or notify user of success
        }
        refreshData();
    };

    return (
        <div>
            {error && <div className='Error' style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
            <div className='New-input'>
                <form onSubmit={handleSubmit}>
                    <div className='new-Input-Form'>
                        <div className='new-Input-Form-Values-Request'>
                            <div className='description'>
                                <textarea value={description} onChange={e => setDescription(e.target.value)} placeholder="Description"></textarea>
                            </div>
                        </div>
                        <div className='add-Run-container'>
                            <button className="add-Run" type="submit">Submit Feedback</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default InputRequest;
