import React, { useState, useContext, useEffect } from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, MenuItem, Select } from '@mui/material';
import { supabase } from '../../supabaseClient';
import { DataContext } from '../../contexts/DataContext';

function BrandPerformanceWidget() {
    const { beerBrands, distributors } = useContext(DataContext); // Using beerBrands and distributors from DataContext
    const [brandPerformance, setBrandPerformance] = useState([]);
    const [totals, setTotals] = useState({
        totalCasesAllocated: 0,
        totalHalfBarrelsAllocated: 0,
        totalQuarterBarrelsAllocated: 0,
        totalSixthBarrelsAllocated: 0,
    });
    const [selectedDistributor, setSelectedDistributor] = useState('');
    const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

    useEffect(() => {
        fetchBrandPerformance();
    }, [selectedDistributor, dateRange]);

    const fetchBrandPerformance = async () => {
        if (!selectedDistributor || !dateRange.startDate || !dateRange.endDate) return;

        const { data, error } = await supabase
            .from('allocationlog')
            .select(`
                beerbrandid,
                casesallocated,
                halfbarrelsallocated,
                quarterbarrelsallocated,
                sixthbarrelsallocated,
                distributorid,
                date
            `)
            .eq('distributorid', selectedDistributor)
            .gte('date', dateRange.startDate)
            .lte('date', dateRange.endDate);

        if (error) {
            console.error('Error fetching brand performance:', error);
            return;
        }

        // Aggregate the allocations by beerbrand
        const performanceMap = {};
        let totalCasesAllocated = 0;
        let totalHalfBarrelsAllocated = 0;
        let totalQuarterBarrelsAllocated = 0;
        let totalSixthBarrelsAllocated = 0;

        data.forEach((log) => {
            if (!performanceMap[log.beerbrandid]) {
                performanceMap[log.beerbrandid] = {
                    casesAllocated: 0,
                    halfBarrelsAllocated: 0,
                    quarterBarrelsAllocated: 0,
                    sixthBarrelsAllocated: 0,
                };
            }

            performanceMap[log.beerbrandid].casesAllocated += log.casesallocated;
            performanceMap[log.beerbrandid].halfBarrelsAllocated += log.halfbarrelsallocated;
            performanceMap[log.beerbrandid].quarterBarrelsAllocated += log.quarterbarrelsallocated;
            performanceMap[log.beerbrandid].sixthBarrelsAllocated += log.sixthbarrelsallocated;

            // Summing totals across all brands
            totalCasesAllocated += log.casesallocated;
            totalHalfBarrelsAllocated += log.halfbarrelsallocated;
            totalQuarterBarrelsAllocated += log.quarterbarrelsallocated;
            totalSixthBarrelsAllocated += log.sixthbarrelsallocated;
        });

        // Update totals state
        setTotals({
            totalCasesAllocated,
            totalHalfBarrelsAllocated,
            totalQuarterBarrelsAllocated,
            totalSixthBarrelsAllocated,
        });

        // Now map beerbrandid to brandname using beerBrands context
        const processedData = Object.keys(performanceMap).map((beerbrandid) => {
            const brand = beerBrands.find((brand) => brand.beerbrandid === parseInt(beerbrandid));
            return {
                brandname: brand ? brand.brandname : 'Unknown',
                totalCasesAllocated: performanceMap[beerbrandid].casesAllocated,
                totalHalfBarrelsAllocated: performanceMap[beerbrandid].halfBarrelsAllocated,
                totalQuarterBarrelsAllocated: performanceMap[beerbrandid].quarterBarrelsAllocated,
                totalSixthBarrelsAllocated: performanceMap[beerbrandid].sixthBarrelsAllocated,
            };
        });

        setBrandPerformance(processedData);
    };

    const handleDateRangeChange = (startDate, endDate) => {
        setDateRange({ startDate, endDate });
    };

    // Helper function to get today's date in YYYY-MM-DD format
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    // Helper function to get the start of the current year in YYYY-MM-DD format
    const getStartOfYear = () => {
        const yearStart = new Date(new Date().getFullYear(), 0, 1);
        return yearStart.toISOString().split('T')[0];
    };

    // Helper function to get the start of the current month in YYYY-MM-DD format
    const getStartOfMonth = () => {
        const monthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        return monthStart.toISOString().split('T')[0];
    };

    const handleYTD = () => {
        const startDate = getStartOfYear();
        const endDate = getTodayDate();
        handleDateRangeChange(startDate, endDate);
    };

    const handleMonthToDate = () => {
        const startDate = getStartOfMonth();
        const endDate = getTodayDate();
        handleDateRangeChange(startDate, endDate);
    };

    const handleAllTime = () => {
        const startDate = '2021-01-01'; // Assuming all-time starts from 2021
        const endDate = getTodayDate();
        handleDateRangeChange(startDate, endDate);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Brand Allocation by Distributor</Typography>

                {/* Distributor Dropdown */}
                <Select
                    value={selectedDistributor}
                    onChange={(e) => setSelectedDistributor(e.target.value)}
                    displayEmpty
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: '16px' }}
                >
                    <MenuItem value="" disabled>Select Distributor</MenuItem>
                    {distributors.map((distributor) => (
                        <MenuItem key={distributor.distributorid} value={distributor.distributorid}>
                            {distributor.distributorname}
                        </MenuItem>
                    ))}
                </Select>

                {/* Time Range and Date Picker */}
                <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                    <Button variant="outlined" onClick={handleYTD} style={{ marginRight: '8px' }}>YTD</Button>
                    <Button variant="outlined" onClick={handleMonthToDate} style={{ marginRight: '8px' }}>Month to Date</Button>
                    <Button variant="outlined" onClick={handleAllTime}>All Time</Button>

                    <TextField
                        label="Start Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.startDate}
                        onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                        style={{ marginRight: '16px' }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={dateRange.endDate}
                        onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                    />
                </div>

                {/* Total Summary for All Brands */}
                <TableContainer component={Paper} style={{ marginBottom: '16px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Total Cases</TableCell>
                                <TableCell>Total 1/2 Barrels</TableCell>
                                <TableCell>Total 1/4 Barrels</TableCell>
                                <TableCell>Total 1/6 Barrels</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{totals.totalCasesAllocated}</TableCell>
                                <TableCell>{totals.totalHalfBarrelsAllocated}</TableCell>
                                <TableCell>{totals.totalQuarterBarrelsAllocated}</TableCell>
                                <TableCell>{totals.totalSixthBarrelsAllocated}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Brand Performance Table */}
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Brand</TableCell>
                                <TableCell>Total Cases</TableCell>
                                <TableCell>Total 1/2 Barrels</TableCell>
                                <TableCell>Total 1/4 Barrels</TableCell>
                                <TableCell>Total 1/6 Barrels</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {brandPerformance.length > 0 ? (
                                brandPerformance.map((row) => (
                                    <TableRow key={row.brandname}>
                                        <TableCell>{row.brandname}</TableCell>
                                        <TableCell>{row.totalCasesAllocated}</TableCell>
                                        <TableCell>{row.totalHalfBarrelsAllocated}</TableCell>
                                        <TableCell>{row.totalQuarterBarrelsAllocated}</TableCell>
                                        <TableCell>{row.totalSixthBarrelsAllocated}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No data available for the selected distributor and date range.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}

export default BrandPerformanceWidget;
