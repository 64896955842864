import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Select, MenuItem } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { supabase } from '../../supabaseClient';

function ProductionWidget() {
    const [productionData, setProductionData] = useState([]);
    const [totalProduction, setTotalProduction] = useState(0);
    const [comparisonPeriod, setComparisonPeriod] = useState('month'); // Example period toggle

    useEffect(() => {
        fetchProductionData(comparisonPeriod);
    }, [comparisonPeriod]);

    const fetchProductionData = async (period) => {
        const { data, error } = await supabase
            .from('batch')
            .select('brewdate, yield')
            .order('brewdate', { ascending: true });

        if (error) {
            console.error('Error fetching production data:', error);
            return;
        }

        const processedData = processProductionData(data, period);
        setProductionData(processedData);
        setTotalProduction(processedData.reduce((sum, item) => sum + item.production, 0));
    };

    const processProductionData = (data, period) => {
        const now = new Date();
        const filteredData = data.filter(item => {
            const date = new Date(item.brewdate);
            switch (period) {
                case 'week':
                    return date >= new Date(now.setDate(now.getDate() - 7));
                case 'month':
                    return date >= new Date(now.setMonth(now.getMonth() - 1));
                case 'year':
                    return date >= new Date(now.setFullYear(now.getFullYear() - 1));
                default:
                    return true;
            }
        });

        const groupedData = filteredData.reduce((acc, cur) => {
            const date = new Date(cur.brewdate);
            const key = `${date.getFullYear()}-${date.getMonth() + 1}`;
            if (!acc[key]) {
                acc[key] = { date: key, production: 0 };
            }
            acc[key].production += cur.yield;
            return acc;
        }, {});

        return Object.values(groupedData);
    };

    const handlePeriodChange = (event) => {
        setComparisonPeriod(event.target.value);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Total Production</Typography>
                <Select
                    value={comparisonPeriod}
                    onChange={handlePeriodChange}
                    displayEmpty
                >
                    <MenuItem value="week">This Week</MenuItem>
                    <MenuItem value="month">This Month</MenuItem>
                    <MenuItem value="year">This Year</MenuItem>
                </Select>
                <Typography variant="h4">{totalProduction.toFixed(2)} barrels</Typography>
                <Box height={300}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={productionData}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="production" stroke="#8884d8" />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </CardContent>
        </Card>
    );
}

export default ProductionWidget;
