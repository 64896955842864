import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

function DistroAllo() {
    const [activeBatches, setActiveBatches] = useState([]);
    const [beerBrands, setBeerBrands] = useState({});
    const [error, setError] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [distributorRows, setDistributorRows] = useState([
        { distributor: '', cases: 0, halfBBL: 0, quarterBBL: 0, sixthBBL: 0, notes: '' }
    ]);

    const trueYield = 10;

    useEffect(() => {
        console.log(selectedBatch);
    }, [selectedBatch]);

    useEffect(() => {
        const fetchRecentBatchesAndBrands = async () => {
            try {
                const { data: batches, error: batchError } = await supabase
                    .from('batch')
                    .select('*')
                    .or('status.is.null,status.eq.Ready To Can')
                    .order('estimatedpackagingdate', { ascending: true });

                if (batchError) throw batchError;

                const { data: brands, error: brandError } = await supabase
                    .from('beerbrands')
                    .select('*');

                if (brandError) throw brandError;

                const brandMap = {};
                brands.forEach((brand) => {
                    brandMap[brand.beerbrandid] = brand.brandname;
                });

                setActiveBatches(batches);
                setBeerBrands(brandMap);
            } catch (error) {
                console.error('Error fetching active batches or beer brands:', error);
                setError('Failed to load active batches');
            }
        };

        fetchRecentBatchesAndBrands();
    }, []);

    const handleAddRow = () => {
        setDistributorRows([...distributorRows, { distributor: '', cases: 0, halfBBL: 0, quarterBBL: 0, sixthBBL: 0, notes: '' }]);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...distributorRows];
        updatedRows[index][field] = value;
        setDistributorRows(updatedRows);
    };

    // Calculate totals for cases and barrels
    const totalCases = distributorRows.reduce((total, row) => total + Number(row.cases), 0);
    const totalHalfBBLs = distributorRows.reduce((total, row) => total + Number(row.halfBBL), 0);
    const totalQuarterBBLs = distributorRows.reduce((total, row) => total + Number(row.quarterBBL), 0);
    const totalSixthBBLs = distributorRows.reduce((total, row) => total + Number(row.sixthBBL), 0);

    // Convert total cases to barrels (1 BBL = 13.33 cases)
    const casesToBarrels = totalCases / 13.33;

    // Convert 1/2 BBLs, 1/4 BBLs, 1/6 BBLs to barrels
    const halfBBLsToBarrels = totalHalfBBLs / 2; // 2 half BBLs = 1 full BBL
    const quarterBBLsToBarrels = totalQuarterBBLs / 4; // 4 quarter BBLs = 1 full BBL
    const sixthBBLsToBarrels = totalSixthBBLs / 6; // 6 sixth BBLs = 1 full BBL

    // Total barrels (cases converted + other barrel types)
    const totalBarrels = casesToBarrels + halfBBLsToBarrels + quarterBBLsToBarrels + sixthBBLsToBarrels;

    // Can Pallets calculation
    const canPallets = (totalCases * 24) / 8169;

    // Runtime calculation in hours
    const runtimeHours = casesToBarrels / 16;

    // Update the totaledBarrels value
    const totaledBarrels = totalBarrels;

    return (
        <div className="distro-allo-container">
            <div className="left-section">
                <div className='distro-allo-right-top'>
                    <h2>Active Batches</h2>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {activeBatches.length === 0 ? (
                        <p>No active batches available</p>
                    ) : (
                        <div className="batch-list">
                            {activeBatches.map((batch) => (
                                <button
                                    key={batch.batchid}
                                    className={`batch-button ${selectedBatch && selectedBatch.batchid === batch.batchid ? 'selected' : ''}`}
                                    onClick={() => setSelectedBatch(batch)}
                                >
                                    {batch.batchid}-{beerBrands[batch.beerbrandid] || 'Unknown Brand'}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <div className="batch-details">
                    {selectedBatch ? (
                        <div>
                            <h4>{beerBrands[activeBatches.find(b => b.batchid === selectedBatch.batchid)?.beerbrandid] || 'Unknown Brand'} ({selectedBatch.batchid}) </h4>
                            <p>Fermenter: FV{selectedBatch?.fermentationvesselid || "Missing FV"}</p>
                            <p>True Yield: {selectedBatch?.yield || 'No Yield Yet'}</p>
                            <p>Brew Date: {selectedBatch?.brewdate || 'Missing'}</p>
                            <p>Crash Date: {selectedBatch?.crashdate || 'Missing'}</p>
                            <p>Packaging Date: {selectedBatch?.estimatedpackagingdate || 'Missing'}</p>
                        </div>
                    ) : (
                        <p>Please select a batch</p>
                    )}
                </div>
            </div>

            <div className="vertical-line"></div>

            <div className="right-section">
                {selectedBatch ? (
                    <div>
                        <div className="grid-container">
                            <div className="distro-allo-row">
                                <div className="cell span-cell">{beerBrands[activeBatches.find(b => b.batchid === selectedBatch.batchid)?.beerbrandid] || 'Unknown Brand'} ({selectedBatch.batchid})</div>
                                <div className="cell span-cell">{selectedBatch.estimatedpackagingdate}</div>
                                <div className="cell span-cell">
                                    {totaledBarrels.toFixed(2)}/{trueYield} BBL
                                </div>
                            </div>

                            <div className="distro-allo-row">
                                <div className="cell">Distributors</div>
                                <div className="cell">Cases</div>
                                <div className="cell">1/2 BBLs</div>
                                <div className="cell">1/4 BBLs</div>
                                <div className="cell">1/6 BBLs</div>
                                <div className="bigger-cell">Notes</div>
                            </div>

                            {distributorRows.map((row, index) => (
                                <div className="distro-allo-row" key={index}>
                                    <div className="bigger-cell">
                                        <input
                                            type="text"
                                            value={row.distributor}
                                            onChange={(e) => handleRowChange(index, 'distributor', e.target.value)}
                                            placeholder="Distributor"
                                        />
                                    </div>
                                    <div className="cell">
                                        <input
                                            type="number"
                                            value={row.cases}
                                            onChange={(e) => handleRowChange(index, 'cases', e.target.value)}
                                            placeholder="Cases"
                                        />
                                    </div>
                                    <div className="cell">
                                        <input
                                            type="number"
                                            value={row.halfBBL}
                                            onChange={(e) => handleRowChange(index, 'halfBBL', e.target.value)}
                                            placeholder="1/2 BBLs"
                                        />
                                    </div>
                                    <div className="cell">
                                        <input
                                            type="number"
                                            value={row.quarterBBL}
                                            onChange={(e) => handleRowChange(index, 'quarterBBL', e.target.value)}
                                            placeholder="1/4 BBLs"
                                        />
                                    </div>
                                    <div className="cell">
                                        <input
                                            type="number"
                                            value={row.sixthBBL}
                                            onChange={(e) => handleRowChange(index, 'sixthBBL', e.target.value)}
                                            placeholder="1/6 BBLs"
                                        />
                                    </div>
                                    <div className="bigger-cell">
                                        <input
                                            type="text"
                                            value={row.notes}
                                            onChange={(e) => handleRowChange(index, 'notes', e.target.value)}
                                            placeholder="Notes"
                                        />
                                    </div>
                                </div>
                            ))}

                            <div className="distro-allo-row">
                                <div className="cell add-new-row-cell">
                                    <button onClick={handleAddRow}>Add New Row</button>
                                </div>
                            </div>

                            {/* Totals row */}
                            <div className="distro-allo-row">
                                <div className="cell">Totals</div>
                                <div className="cell">{totalCases}</div>
                                <div className="cell">{totalHalfBBLs}</div>
                                <div className="cell">{totalQuarterBBLs}</div>
                                <div className="cell">{totalSixthBBLs}</div>
                                <div className="cell"></div>
                            </div>

                            {/* Total Barrels row */}
                            <div className="distro-allo-row">
                                <div className="cell">Total Barrels</div>
                                <div className="cell">{casesToBarrels.toFixed(2)} BBL</div>
                                <div className="cell">{halfBBLsToBarrels.toFixed(2)} BBL</div>
                                <div className="cell">{quarterBBLsToBarrels.toFixed(2)} BBL</div>
                                <div className="cell">{sixthBBLsToBarrels.toFixed(2)} BBL</div>
                                <div className="cell"></div>
                            </div>

                            {/* Can Pallets and Runtime row */}
                            <div className="distro-allo-row">
                                <div className="cell">Can Pallets</div>
                                <div className="cell">{canPallets.toFixed(2)}</div>
                                <div className="cell"></div>
                                <div className="cell"></div>
                                <div className="cell">Runtime (hours)</div>
                                <div className="cell">{runtimeHours.toFixed(2)} hrs</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Please select a batch</p>
                )}
            </div>
        </div>
    );
}

export default DistroAllo;
