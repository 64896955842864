import React, { useState, useContext } from 'react';
import { DataContext } from '../contexts/DataContext';

function Settings() {
    const { beerBrands, distributors } = useContext(DataContext);

    // State to manage edit mode and track changes
    const [editMode, setEditMode] = useState(false);
    const [updatedBeerBrands, setUpdatedBeerBrands] = useState([...beerBrands]);

    // Function to handle edit mode toggle
    const toggleEditMode = () => {
        setEditMode(!editMode);
        if (!editMode) {
            // Exit edit mode, reset changes
            setUpdatedBeerBrands([...beerBrands]);
        }
    };

    // Function to handle datetime changes for each brand
    const handleDatetimeChange = (index, field, value) => {
        const updatedBrands = [...updatedBeerBrands];
        updatedBrands[index][field] = value;
        setUpdatedBeerBrands(updatedBrands);
    };

    // Function to handle submission of the changes
    const handleSubmitChanges = () => {
        // Here you would add logic to submit the updated data to the database
        console.log('Submitting changes:', updatedBeerBrands);

        // Once submitted, disable edit mode
        setEditMode(false);
    };

    // Function to cancel changes and exit edit mode
    const handleCancelChanges = () => {
        setUpdatedBeerBrands([...beerBrands]);
        setEditMode(false);
    };

    return (
        <div className="settings-page">
            <div className="tables-container">
                {/* Beer Brands Table */}
                <div className="table-container">
                    <div className='settings-brand-header'>
                        <h2>Beer Brands</h2>
                        <div className="edit-mode-header">
                            {!editMode ? (
                                <button className="edit-button" onClick={toggleEditMode}>Edit</button>
                            ) : (
                                <>
                                    <button className="brand-date-submit-button" onClick={handleSubmitChanges}>Submit</button>
                                    <button className="brand-date-cancel-button" onClick={handleCancelChanges}>Cancel</button>
                                </>
                            )}
                        </div>
                    </div>
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Brand Name</th>
                                <th>Brew to Crash</th>
                                <th>Brew to Dry hop</th>
                                <th>Crash to Packaging</th>
                            </tr>
                        </thead>
                        <tbody>
                            {updatedBeerBrands.map((brand, index) => (
                                <tr key={brand.beerbrandid}>
                                    <td>{brand.brandname}</td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                className='shorter-input'
                                                type="number"
                                                value={brand.crashdatetime}
                                                onChange={(e) =>
                                                    handleDatetimeChange(index, 'crashdatetime', e.target.value)
                                                }
                                            />
                                        ) : (
                                            brand.crashdatetime
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                className='shorter-input'
                                                type="number"
                                                value={brand.dryhopdatetime}
                                                onChange={(e) =>
                                                    handleDatetimeChange(index, 'dryhopdatetime', e.target.value)
                                                }
                                            />
                                        ) : (
                                            brand.dryhopdatetime
                                        )}
                                    </td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                className='shorter-input'
                                                type="number"
                                                value={brand.estimatedpackagingdatetime}
                                                onChange={(e) =>
                                                    handleDatetimeChange(index, 'estimatedpackagingdatetime', e.target.value)
                                                }
                                            />
                                        ) : (
                                            brand.estimatedpackagingdatetime
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="add-button" onClick={() => console.log('Add new brand clicked')}>Add New Brand</button>
                </div>

                {/* Distributors Table */}
                <div className="table-container">
                    <h2>Distributors</h2>
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Distributor ID</th>
                                <th>Distributor Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {distributors.map((distributor) => (
                                <tr key={distributor.distributorid}>
                                    <td>{distributor.distributorid}</td>
                                    <td>{distributor.distributorname}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="add-button" onClick={() => console.log('Add new distributor clicked')}>Add New Distributor</button>
                </div>
            </div>
        </div>
    );
}

export default Settings;
