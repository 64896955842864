import React, { useState, useContext, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { DataContext } from '../contexts/DataContext';

function ToPubAllocation() {
    const { refreshData, beerBrands, distributors } = useContext(DataContext);
    const { allocationLogData, refreshAllocationLogData } = useContext(DataContext);
    const { pubInventory, refreshPubInventory } = useContext(DataContext);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [cases, setCases] = useState(0);
    const [halfBarrels, setHalfBarrels] = useState(0);
    const [batch, setBatch] = useState([]);
    const [viewMode, setViewMode] = useState('allocation'); // 'allocation' or 'history'
    const [allocationHistory, setAllocationHistory] = useState([]);
    const [toPubDistributorId, setToPubDistributorId] = useState(null);
    const [currentPubInventory, setCurrentPubInventory] = useState({ cases: 0, halfBarrels: 0 });

    useEffect(() => {
        const toPubDistributor = distributors.find(d => d.distributorname === "2 Pub");
        if (toPubDistributor) setToPubDistributorId(toPubDistributor.distributorid);

        if (viewMode === 'history') {
            fetchAllocationHistory();
        }
    }, [viewMode, distributors]);

    useEffect(() => {
        if (selectedBrand) {
            refreshPubInventory();
            calculatePubInventory(selectedBrand, pubInventory);
        }
    }, [selectedBrand]);

    const fetchAllocationHistory = async () => {
        if (!toPubDistributorId) return;
        const today = new Date();
        const hundredDaysAgo = new Date();
        hundredDaysAgo.setDate(today.getDate() - 100);

        const filteredData = allocationLogData.filter(allocation => {
            const allocationDate = new Date(allocation.date);
            return allocation.distributorid === toPubDistributorId && allocationDate >= hundredDaysAgo && allocationDate <= today;
        });

        setAllocationHistory(filteredData);
    };

    const handleAddToBatch = () => {
        const newAllocation = {
            beerbrandid: parseInt(selectedBrand), // Convert to integer
            casesallocated: parseInt(cases),
            halfbarrelsallocated: parseInt(halfBarrels),
            quarterbarrelsallocated: 0,
            sixthbarrelsallocated: 0,
        };

        setBatch([...batch, newAllocation]);

        setCases(0);
        setHalfBarrels(0);
        setSelectedBrand('');
    };

    const calculatePubInventory = async (brandId, pub) => {
        try {
            const pubTotal = pub.find(u => u.beerbrandid === parseInt(brandId, 10));

            setCurrentPubInventory({
                cases: pubTotal.totalcases,
                halfBarrels: pubTotal.totalhalfbarrels
            });
        } catch (error) {
            console.error('Error calculating Pub inventory:', error.message);
        }
    };

    const handleDeleteItem = (index) => {
        const newBatch = batch.filter((_, i) => i !== index);
        setBatch(newBatch);
    };

    const handleSubmitBatch = async () => {
        if (!toPubDistributorId) {
            alert("Error: '2 Pub' distributor not found");
            return;
        }

        try {
            const allocationsToInsert = batch.map(item => ({
                date: new Date().toISOString().split('T')[0], // Current date
                distributorid: toPubDistributorId,
                ...item
            }));

            const { data, error } = await supabase
                .from('allocationlog')
                .insert(allocationsToInsert);

            if (error) throw error;

            setBatch([]);
            refreshAllocationLogData();
            refreshData();
        } catch (error) {
            console.error('Error submitting batch:', error.message);
        }
    };

    const toggleViewMode = () => {
        setViewMode(viewMode === 'allocation' ? 'history' : 'allocation');
    };

    return (
        <div>
            <div className='topub-header'>
                <button onClick={toggleViewMode}>
                    {viewMode === 'allocation' ? 'View History' : 'New Allocation'}
                </button>
            </div>
            <div className='topub-window'>
                {viewMode === 'allocation' ? (
                    <>
                        <div className='topub-input'>
                            <div className='brand-dropdown'>
                                <label htmlFor="brand">Brand</label>
                                <select
                                    id="brand"
                                    value={selectedBrand}
                                    onChange={(e) => setSelectedBrand(e.target.value)}
                                >
                                    <option value="">Select Brand</option>
                                    {beerBrands.map((brand) => (
                                        <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                            {brand.brandname}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='topub-cases'>
                                <label htmlFor="cases">Cases</label>
                                <input
                                    id="cases"
                                    type="number"
                                    value={cases === 0 ? '' : cases} // Empty string if 0
                                    onChange={(e) => setCases(e.target.value)}
                                />
                            </div>

                            <div className='topub-halves'>
                                <label htmlFor="halfBarrels">1/2 Barrels</label>
                                <input
                                    id="halfBarrels"
                                    type="number"
                                    value={halfBarrels === 0 ? '' : halfBarrels}
                                    onChange={(e) => setHalfBarrels(e.target.value)}
                                />
                            </div>
                            <div className='pub-inventory'>
                                <p>Pub Inventory Cases: {currentPubInventory.cases}</p>
                                <p>Pub Inventory 1/2 Barrels: {currentPubInventory.halfBarrels}</p>
                            </div>
                        </div>

                        <div className='topub-button'>
                            <button onClick={handleAddToBatch}>Add {'>'}</button>
                        </div>

                        <div className='topub-batchview'>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Brand</th>
                                        <th>Cases</th>
                                        <th>Half Barrels</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {batch.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <button onClick={() => handleDeleteItem(index)}>X</button>
                                            </td>
                                            <td>{beerBrands.find(brand => brand.beerbrandid === item.beerbrandid)?.brandname || 'Unknown'}</td>
                                            <td>{item.casesallocated}</td>
                                            <td>{item.halfbarrelsallocated}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className='topub-button'>
                            <button onClick={handleSubmitBatch}>Submit</button>
                        </div>
                    </>
                ) : (
                    <div className='topub-history'>
                        <h3>2 Pub Allocation History</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Brand</th>
                                    <th>Cases</th>
                                    <th>Half Barrels</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allocationHistory.map((allocation, index) => (
                                    <tr key={index}>
                                        <td>{allocation.date}</td>
                                        <td>{beerBrands.find(brand => brand.beerbrandid === allocation.beerbrandid)?.brandname}</td>
                                        <td>{allocation.casesallocated}</td>
                                        <td>{allocation.halfbarrelsallocated}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>

    );
}

export default ToPubAllocation;