import React, { useEffect, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { standardizeDateHandling } from '../../utils/dateUtils';
import trash from '../../media/trash.png'


const BrewVesselStatus = ({ selectedVessel, vessels, activeBatches, beerBrands, setBrewState, handleMovetoBriteSubmit, handleDeleteBatch, updateBatchInDB }) => {
    const [loading, setLoading] = useState(true);
    const [localSelectedBatchData, setLocalSelectedBatchData] = useState({});

    const selectedVesselData = useMemo(() => {
        return vessels.find(vessel => vessel.fermentationvesselid === selectedVessel) || {};
    }, [vessels, selectedVessel]);

    const selectedBatchData = useMemo(() => {
        return activeBatches.find(batch => batch.batchid === selectedVesselData.batchid) || {};
    }, [activeBatches, selectedVesselData]);

    const selectedBeerBrandData = useMemo(() => {
        return beerBrands.find(beerbrand => beerbrand.beerbrandid === localSelectedBatchData?.beerbrandid) || {};
    }, [beerBrands, localSelectedBatchData]);

    useEffect(() => {
        if (selectedBatchData && Object.keys(selectedBatchData).length > 0) {
            setLocalSelectedBatchData(selectedBatchData);
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [selectedBatchData]);

    if (loading) {
        return <div>Loading...</div>;
    };

    const clearDryHopDate = () => {
        const updatedBatchData = { ...localSelectedBatchData, dryhopdate: null };
        setLocalSelectedBatchData(updatedBatchData);
        updateBatchInDB(updatedBatchData);
    };

    const updateBatchData = (key, value, shouldUpdateDB = false) => {
        const updatedBatchData = { ...localSelectedBatchData, [key]: value };
        setLocalSelectedBatchData(updatedBatchData);
        setBrewState(updatedBatchData);

        if (shouldUpdateDB) {
            updateBatchInDB(updatedBatchData);
        }
    };

    return (
        <div>
            <div className='brew-form-header'>
                <button className="trashIcon" type="button" onClick={() => handleDeleteBatch(selectedVesselData.batchid, 'FV', selectedVessel)}>
                    <img src={trash} alt="trash" />
                </button>
                <h3>Status of FV{selectedVessel} ({selectedVesselData.batchid})</h3>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleMovetoBriteSubmit(e);
            }}>
                <table>
                    <tbody>
                        <tr>
                            <td>Status:</td>
                            <td>{selectedVesselData.status}</td>
                        </tr>
                        <tr>
                            <td>Capacity:</td>
                            <td>{selectedVesselData.capacity}</td>
                        </tr>

                        <tr>
                            <td>Brand:</td>
                            <td>
                                <select
                                    value={localSelectedBatchData?.brandname || ''}
                                    onChange={(e) => updateBatchData('beerbrandid', parseInt(e.target.value), true)}
                                >
                                    {selectedBeerBrandData?.brandname ? (
                                        <option value={selectedBeerBrandData.beerbrandid}>
                                            {selectedBeerBrandData.brandname}
                                        </option>
                                    ) : (
                                        <option value="">Select a brand</option>
                                    )}
                                    {beerBrands.map((brand) => (
                                        <option key={brand.beerbrandid} value={brand.beerbrandid}>
                                            {brand.brandname}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Brew Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.brewdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.brewdate) : null}
                                    onChange={(date) => updateBatchData('brewdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.brewdateworkerid || ''}
                                    onChange={(e) => updateBatchData('brewdateworkerid', e.target.value)}
                                    onBlur={(e) => updateBatchData('brewdateworkerid', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Dry Hop Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.dryhopdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.dryhopdate) : null}
                                    onChange={(date) => updateBatchData('dryhopdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.dryhopdateworkerid || ''}
                                    onChange={(e) => updateBatchData('dryhopdateworkerid', e.target.value)}
                                    onBlur={(e) => updateBatchData('dryhopdateworkerid', e.target.value, true)}
                                />
                                <button type="button" onClick={clearDryHopDate}>
                                    x
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Crash Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.crashdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.crashdate) : null}
                                    onChange={(date) => updateBatchData('crashdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.crashdateworkerid || ''}
                                    onChange={(e) => updateBatchData('crashdateworkerid', e.target.value)}
                                    onBlur={(e) => updateBatchData('crashdateworkerid', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>O.G.:</td>
                            <td>
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.originalgravity || ''}
                                    onChange={(e) => updateBatchData('originalgravity', e.target.value)}
                                    onBlur={(e) => updateBatchData('originalgravity', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Final Gravity:</td>
                            <td>
                                <input
                                    className='small-box'
                                    type="text"
                                    value={localSelectedBatchData?.finalgravity || ''}
                                    onChange={(e) => updateBatchData('finalgravity', e.target.value)}
                                    onBlur={(e) => updateBatchData('finalgravity', e.target.value, true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Package Date:</td>
                            <td>
                                <DatePicker
                                    className='medium-box'
                                    selected={localSelectedBatchData.estimatedpackagingdate ? standardizeDateHandling.fromStorageFormat(localSelectedBatchData.estimatedpackagingdate) : null}
                                    onChange={(date) => updateBatchData('estimatedpackagingdate', standardizeDateHandling.toStorageFormat(date), true)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Notes:</td>
                            <td>
                                <textarea
                                    value={localSelectedBatchData?.notes || ''}
                                    onChange={(e) => updateBatchData('notes', e.target.value)}
                                    onBlur={(e) => updateBatchData('notes', e.target.value, true)}
                                ></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type="submit">Move to Brite</button>
            </form>
        </div>
    );
};

export default BrewVesselStatus;
